var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "h-full p-4 overflow-y-auto shadow-inner" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "confirmo1RankDate",
            label: "Confirm O-1 Rank",
            alignment: "top",
          },
          model: {
            value: _vm.student.officerTrainingSchoolConfirmo1rankdate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolConfirmo1rankdate",
                $$v
              )
            },
            expression: "student.officerTrainingSchoolConfirmo1rankdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "updateDutyInfoDate", label: "Update Duty Info" },
          model: {
            value: _vm.student.officerTrainingSchoolUpdatedutyinfodate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolUpdatedutyinfodate",
                $$v
              )
            },
            expression: "student.officerTrainingSchoolUpdatedutyinfodate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "deleteDoeDate", label: "Delete DOE" },
          model: {
            value: _vm.student.officerTrainingSchoolDeletedoedate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolDeletedoedate", $$v)
            },
            expression: "student.officerTrainingSchoolDeletedoedate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "deleteEtsDate", label: "Delete ETS" },
          model: {
            value: _vm.student.officerTrainingSchoolDeleteetsdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolDeleteetsdate", $$v)
            },
            expression: "student.officerTrainingSchoolDeleteetsdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "deleteEtoDate", label: "Delete ETO" },
          model: {
            value: _vm.student.officerTrainingSchoolDeleteetodate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolDeleteetodate", $$v)
            },
            expression: "student.officerTrainingSchoolDeleteetodate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "awardsDate", label: "Awards - ND/TR/TS" },
          model: {
            value: _vm.student.officerTrainingSchoolAwardsdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolAwardsdate", $$v)
            },
            expression: "student.officerTrainingSchoolAwardsdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "rscUptDate", label: "RSC (UPT)" },
          model: {
            value: _vm.student.officerTrainingSchoolRscuptdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolRscuptdate", $$v)
            },
            expression: "student.officerTrainingSchoolRscuptdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "aoFromAfpcDate", label: "AO From ARPC" },
          model: {
            value: _vm.student.officerTrainingSchoolAofromafpcdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolAofromafpcdate", $$v)
            },
            expression: "student.officerTrainingSchoolAofromafpcdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "dissDate", label: "DISS" },
          model: {
            value: _vm.student.officerTrainingSchoolDissdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolDissdate", $$v)
            },
            expression: "student.officerTrainingSchoolDissdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "compcatDate",
            label: "COMPCAT",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.officerTrainingSchoolCompcatdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolCompcatdate", $$v)
            },
            expression: "student.officerTrainingSchoolCompcatdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "af475CompleteDate",
            label: "AF475",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.officerTrainingSchoolAf475completedate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolAf475completedate",
                $$v
              )
            },
            expression: "student.officerTrainingSchoolAf475completedate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "postCompleteDate",
            label: "Post Complete",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.officerTrainingSchoolPostcompletedate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolPostcompletedate",
                $$v
              )
            },
            expression: "student.officerTrainingSchoolPostcompletedate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "oathOfOfficeDate",
            label: "Oath of Office",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.officerTrainingSchoolOathofofficedate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolOathofofficedate",
                $$v
              )
            },
            expression: "student.officerTrainingSchoolOathofofficedate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "font-semibold" }, [_vm._v("After OTS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }