import { EHoldTypes } from '@/enums/EHoldTypes';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Hold } from '@/models/Entities/MaterializedViews/Hold';
import { DisplayNameUtil } from '@/util/displayName';

export class HoldsTableData {
  candidateId!: number;
  candidate!: BaseCandidate;
  holdType!: EHoldTypes | null;
  orderEnd!: Date | null;
  days!: number | null;
  name!: string | null;

  constructor(hold: Hold) {
    this.candidateId = hold.candidateId;
    this.candidate = hold.candidate;
    this.holdType = hold.flightPayHoldtype;
    this.orderEnd = hold.orderEndDate;
    this.days = hold.days;
    // first and last names are not nullable in candidate so this is expected to evaluate to true
    if (hold.firstName && hold.lastName) {
      this.name = DisplayNameUtil.flattenName(
        hold.firstName.toUpperCase(),
        hold.lastName.toUpperCase()
      );
    }
  }
}
