import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchOutProDto } from '@/models/Dtos/searchOutProDto';
import { OutPro } from '@/models/Entities/MaterializedViews/OutPro';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { outProTableConfigBuilder } from './OutProConfigBuilder';
import { OutProDataProvider } from './OutProDataProvider';
import { OutProTableData } from './OutProTableData';

export const outProMiniTableConfig: IMiniTableConfiguration<
  OutProTableData,
  OutPro,
  never,
  SearchOutProDto
> = {
  component: {
    component: MiniTable,
    title: 'Out-Pro',
    key: 'Out Pro'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      OutProTableData,
      OutPro,
      never,
      SearchOutProDto
    >(
      store,
      new OutProDataProvider(store),
      {},
      { defaultSortProperty: 'lastName' },
      outProTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  }
};
