import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchCensusDto } from '@/models/Dtos/searchCensusDto';
import { Census } from '@/models/Entities/MaterializedViews/Census';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { censusTableConfigBuilder } from './CensusConfigBuilder';
import { CensusDataProvider } from './CensusDataProvider';
import { CensusTableData } from './CensusTableData';

export const censusMiniTableConfig: IMiniTableConfiguration<
  CensusTableData,
  Census,
  never,
  SearchCensusDto
> = {
  component: {
    component: MiniTable,
    title: 'Census',
    key: 'Census'
  },
  emptyTableMessage: 'No Census',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      CensusTableData,
      Census,
      never,
      SearchCensusDto
    >(
      store,
      new CensusDataProvider(store),
      {},
      { defaultSortProperty: 'total' },
      censusTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  }
};
