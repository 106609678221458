import { BadgeType } from '@/enums';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { dateDiffFromNow } from '@/util/date';
import { DisplayNameUtil } from '@/util/displayName';

export class GainsTableData {
  id!: number;
  badge!: BadgeType | null;
  idle!: number;
  medicalClearance!: string | null;
  step!: number;
  groupInfo!: string | null;
  name!: string | null;
  candidate!: BaseCandidate;

  constructor(gains: Gains) {
    this.id = gains.candidateId;
    this.badge = gains.badge;
    this.idle = dateDiffFromNow(gains.studentUftBoardDateSelected);
    this.medicalClearance = gains.medicalClearanceStatus;
    this.step = gains.step;
    this.groupInfo = gains.groupInfo;
    this.name = DisplayNameUtil.flattenName(
      gains.firstName.toUpperCase(),
      gains.lastName.toUpperCase()
    );
    this.candidate = gains.candidate;
  }
}
