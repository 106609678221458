import ActionCompletedButtonComponent from '@/components/Table/Elements/ActionCompletedButtonComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { ImmediateActionTableData } from './ImmediateActionTableData';
import { ImmediateAction } from '@/models/Entities/MaterializedViews/ImmediateAction';
import { SearchImmediateActionDto } from '@/models/Dtos/searchImmediateActionDto';

export const immediateActionTableConfigBuilder: TableConfigBuilder<
  ImmediateActionTableData,
  ImmediateAction,
  never,
  SearchImmediateActionDto
> = (): ITableConfiguration<
  ImmediateActionTableData,
  ImmediateAction,
  never
> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: ImmediateActionTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Topic',
        render: 'topic',
        sortKey: 'topic'
      },
      {
        columnTitle: 'Person of Contact',
        render: 'personOfContact'
      },
      {
        columnTitle: 'Date Created',
        render: 'dateCreated',
        sortKey: 'dateCreated'
      },
      {
        columnTitle: 'Mark Completed',
        render: {
          componentRef: ActionCompletedButtonComponent,
          componentProps: (
            rowData: ImmediateActionTableData
          ): Record<string, unknown> => {
            return {
              log: rowData.log
            };
          }
        }
      }
    ]
  };
};
