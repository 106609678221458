var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition",
    { attrs: { name: "slide-fade" } },
    [
      _vm.show
        ? _c(
            "student-selection-flyout-component",
            {
              attrs: { title: "In Processing", students: _vm.students },
              on: {
                save: function ($event) {
                  return _vm.updateActiveStudent(_vm.activeStudent)
                },
                cancel: _vm.close,
                selectStudent: _vm.handleSelectStudent,
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex h-full" },
                [
                  _c("student-vitals-component", {
                    staticClass:
                      "flex-grow flex-shrink-0 overflow-y-auto border-l bg-gray-50",
                    attrs: { readonly: true, student: _vm.activeStudent },
                  }),
                  _c("student-info-component", {
                    staticClass:
                      "flex-grow flex-shrink-0 overflow-y-auto border-l",
                    model: {
                      value: _vm.activeStudent,
                      callback: function ($$v) {
                        _vm.activeStudent = $$v
                      },
                      expression: "activeStudent",
                    },
                  }),
                  _c("gtc-column-component", {
                    staticClass:
                      "flex-grow flex-shrink-0 overflow-y-auto border-l",
                    model: {
                      value: _vm.activeStudent,
                      callback: function ($$v) {
                        _vm.activeStudent = $$v
                      },
                      expression: "activeStudent",
                    },
                  }),
                  _c("letters-column-component", {
                    staticClass:
                      "flex-grow flex-shrink-0 overflow-y-auto border-l",
                    model: {
                      value: _vm.activeStudent,
                      callback: function ($$v) {
                        _vm.activeStudent = $$v
                      },
                      expression: "activeStudent",
                    },
                  }),
                  _c("final-dates-column-component", {
                    staticClass:
                      "flex-grow flex-shrink-0 overflow-y-auto border-l",
                    model: {
                      value: _vm.activeStudent,
                      callback: function ($$v) {
                        _vm.activeStudent = $$v
                      },
                      expression: "activeStudent",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }