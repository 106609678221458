import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchInProDto } from '@/models/Dtos/searchInProDto';
import { InPro } from '@/models/Entities/MaterializedViews/InPro';
import { InProTableData } from './InProTableData';

export class InProDataProvider extends TableDataProvider<
  InProTableData,
  InPro,
  SearchInProDto
> {
  protected transformer = (t: InPro): InProTableData => new InProTableData(t);

  protected queryHandler = async (
    searchDto: SearchInProDto
  ): Promise<SearchResponseDto<InPro>> => {
    const results: SearchResponseDto<InPro> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'inpro', query: searchDto, model: InPro }
    );
    return results;
  };
}
