import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';

export class Transition {
  @Type(() => Number)
  candidateId!: number | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  previousCourseEndDate!: Date | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  nextCourseStartDate!: Date | null;
  @Type(() => Number)
  daysBetweenLastCourseAndNextCourse!: number;
  firstName!: string | null;
  lastName!: string | null;
  candidate!: BaseCandidate;

  constructor(partial?: Partial<Transition>) {
    if (partial) {
      Object.assign(this, plainToClass(Transition, partial));
    }
  }
}
