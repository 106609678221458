/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class LocalStorageMixin extends Vue {
  $window!: {
    localStorage: any;
  };
  localStorageSetItem(key: string, value: any): void {
    this.$window.localStorage.setItem(key, JSON.stringify(value));
  }
  localStorageGetItem(key: string): any {
    const foundItem = this.$window.localStorage.getItem(key);
    return foundItem ? JSON.parse(foundItem) : null;
  }
}
