import { DisplayNameUtil } from '@/util/displayName';
import { dateDifference } from '@/util/date';
import { StartPay } from '../../../../models/Entities/MaterializedViews/StartPay';
import { BaseCandidate } from '../../../../models/Entities/BaseCandidate';

export class StartPayTableData {
  candidateId!: number;
  candidate!: BaseCandidate;
  name!: string;
  startDate!: Date | null;
  modEndDate!: Date | null;
  emailDate!: Date | null;
  mod!: number | null;
  trackingNumber!: number | null;
  days!: number | null;

  getStartPayDays(endDate: Date | null): number | null {
    return !endDate ? null : dateDifference(new Date(), new Date(endDate));
  }
  constructor(startpay: StartPay) {
    this.candidateId = startpay.candidateId;
    this.candidate = startpay.candidate;
    if (startpay.firstName && startpay.lastName) {
      this.name = DisplayNameUtil.flattenName(
        startpay.firstName.toUpperCase(),
        startpay.lastName.toUpperCase()
      );
    }
    this.startDate = startpay.startDate;
    this.modEndDate = startpay.modEndDate;
    this.emailDate = startpay.emailDate;
    this.mod = startpay.mod;
    this.trackingNumber = startpay.trackingNumber;
    this.days = this.getStartPayDays(startpay.modEndDate);
  }
}
