var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "date-selector",
    _vm._g(
      _vm._b(
        {
          attrs: {
            yearsForward: 0,
            setTodaysDateOnFirstClick: true,
            nullable: true,
            readonly: _vm.readonly,
          },
        },
        "date-selector",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }