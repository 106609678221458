import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchImmediateActionDto } from '@/models/Dtos/searchImmediateActionDto';
import { ImmediateAction } from '@/models/Entities/MaterializedViews/ImmediateAction';
import { ImmediateActionTableData } from './ImmediateActionTableData';

export class ImmediateActionDataProvider extends TableDataProvider<
  ImmediateActionTableData,
  ImmediateAction,
  SearchImmediateActionDto
> {
  protected transformer = (t: ImmediateAction): ImmediateActionTableData =>
    new ImmediateActionTableData(t);

  protected queryHandler = async (
    searchDto: SearchImmediateActionDto
  ): Promise<SearchResponseDto<ImmediateAction>> => {
    const results: SearchResponseDto<ImmediateAction> =
      await this.store.dispatch(
        'materializedModule/fetchWithSelectParameters',
        { path: 'ImmediateAction', query: searchDto, model: ImmediateAction }
      );
    return results;
  };
}
