var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _c("div", { staticClass: "flex-shrink-0 border-b p-7" }),
    _c(
      "div",
      { staticClass: "p-4 overflow-y-auto shadow-inner" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "fmPackageDate",
            label: "FM Package",
            alignment: "top",
          },
          model: {
            value: _vm.student.inProcessingFmpackagedate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingFmpackagedate", $$v)
            },
            expression: "student.inProcessingFmpackagedate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "lettersDate", label: "Letters" },
          model: {
            value: _vm.student.inProcessingUniformlettersdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingUniformlettersdate", $$v)
            },
            expression: "student.inProcessingUniformlettersdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "rscDate", label: "RSC" },
          model: {
            value: _vm.student.inProcessingRscdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingRscdate", $$v)
            },
            expression: "student.inProcessingRscdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "fundingDate", label: "Funding" },
          model: {
            value: _vm.student.inProcessingFundingdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingFundingdate", $$v)
            },
            expression: "student.inProcessingFundingdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "shotRecordDate", label: "Shot Record" },
          model: {
            value: _vm.student.inProcessingShotrecorddate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingShotrecorddate", $$v)
            },
            expression: "student.inProcessingShotrecorddate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "af2583Date", label: "AF2583" },
          model: {
            value: _vm.student.inProcessingAf2583date,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingAf2583date", $$v)
            },
            expression: "student.inProcessingAf2583date",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "confirmEftDate", label: "Confirm EFT" },
          model: {
            value: _vm.student.inProcessingConfirmeftdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingConfirmeftdate", $$v)
            },
            expression: "student.inProcessingConfirmeftdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "millConnectDate", label: "Mil Connect" },
          model: {
            value: _vm.student.inProcessingMillconnectdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingMillconnectdate", $$v)
            },
            expression: "student.inProcessingMillconnectdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "educationTransDate",
            label: "Education Transcript",
          },
          model: {
            value: _vm.student.inProcessingEducationtransdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingEducationtransdate", $$v)
            },
            expression: "student.inProcessingEducationtransdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "natoBriefDate", label: "NATO Brief" },
          model: {
            value: _vm.student.inProcessingNatobriefdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingNatobriefdate", $$v)
            },
            expression: "student.inProcessingNatobriefdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "certifiedInprocessingDate",
            label: "Certified Inpro",
          },
          model: {
            value: _vm.student.inProcessingCertifiedinprocessingdate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "inProcessingCertifiedinprocessingdate",
                $$v
              )
            },
            expression: "student.inProcessingCertifiedinprocessingdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "orderModRoutedDate", label: "Order Mod Routed" },
          model: {
            value: _vm.student.inProcessingOrdermodrouteddate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingOrdermodrouteddate", $$v)
            },
            expression: "student.inProcessingOrdermodrouteddate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "officerTrainingSchoolOrderDate",
            label: "OTS Order",
          },
          model: {
            value: _vm.student.inProcessingOfficertrainingschoolorderdate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "inProcessingOfficertrainingschoolorderdate",
                $$v
              )
            },
            expression: "student.inProcessingOfficertrainingschoolorderdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "inproccesingVoucherDate",
            label: "Inpro Voucher",
          },
          model: {
            value: _vm.student.inProcessingInproccesingvoucherdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingInproccesingvoucherdate", $$v)
            },
            expression: "student.inProcessingInproccesingvoucherdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "routeOrderDate",
            label: "Route Order",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.inProcessingRouteorderdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingRouteorderdate", $$v)
            },
            expression: "student.inProcessingRouteorderdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: {
            "data-cy": "myLearningTranscriptDate",
            label: "My Learning Transcript",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.inProcessingMylearningtranscriptdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingMylearningtranscriptdate", $$v)
            },
            expression: "student.inProcessingMylearningtranscriptdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-6",
          attrs: { "data-cy": "mmpaDate", label: "MMPA", alignment: "bottom" },
          model: {
            value: _vm.student.inProcessingMmpadate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "inProcessingMmpadate", $$v)
            },
            expression: "student.inProcessingMmpadate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }