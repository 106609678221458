import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { GainsTableData } from './GainsTableData';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';

export const gainsTableConfigBuilder: TableConfigBuilder<
  GainsTableData,
  Gains,
  never,
  SearchGainsDto
> = (): ITableConfiguration<GainsTableData, Gains, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: GainsTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.id }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Badge',
        render: 'badge',
        sortKey: 'badge'
      },
      {
        columnTitle: 'Idle',
        render: 'idle',
        sortKey: 'studentUftBoardDateSelected'
      },
      {
        columnTitle: 'Medical Clearance',
        render: 'medicalClearance',
        sortKey: 'medicalClearanceStatus'
      },
      {
        columnTitle: 'Step',
        render: 'step'
      },
      {
        columnTitle: 'Group Info',
        render: 'groupInfo',
        sortKey: 'groupInfo'
      }
    ]
  };
};
