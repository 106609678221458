import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { StartPayTableData } from './StartPayTableData';
import { StartPay } from '../../../../models/Entities/MaterializedViews/StartPay';
import { SearchStartPayDto } from '../../../../models/Dtos/searchStartPayDto';

export const startPayTableConfigBuilder: TableConfigBuilder<
  StartPayTableData,
  StartPay,
  never,
  SearchStartPayDto
> = (): ITableConfiguration<StartPayTableData, StartPay, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: StartPayTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Init Start',
        render: 'startDate',
        sortKey: 'startDate'
      },
      {
        columnTitle: 'Mod End',
        render: 'modEndDate',
        sortKey: 'modEndDate'
      },
      {
        columnTitle: 'Mod',
        render: 'mod',
        sortKey: 'mod'
      },
      {
        columnTitle: 'Days',
        render: 'days',
        sortKey: 'modEndDate'
      },
      {
        columnTitle: 'Track No.',
        render: 'trackingNumber',
        sortKey: 'trackingNumber'
      },
      {
        columnTitle: 'Email Sent',
        render: 'emailDate',
        sortKey: 'emailDate'
      }
    ]
  };
};
