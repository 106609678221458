import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchTransitionDto } from '@/models/Dtos/searchTransitionDto';
import { Transition } from '@/models/Entities/MaterializedViews/Transition';
import { TableDataProvider } from '../../../Table/models/TableDataProvider';
import { TransitionTableData } from './TransitionTableData';

export class TransitionDataProvider extends TableDataProvider<
  TransitionTableData,
  Transition,
  SearchTransitionDto
> {
  protected transformer = (t: Transition): TransitionTableData =>
    new TransitionTableData(t);

  protected queryHandler = async (
    searchDto: SearchTransitionDto
  ): Promise<SearchResponseDto<Transition>> => {
    const results: SearchResponseDto<Transition> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'transition', query: searchDto, model: Transition }
    );
    return results;
  };
}
