var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-container" }, [
      _c("p", [_vm._v("Sir/Ma’am,")]),
      _c("p", [
        _vm._v(
          " Your Government Travel Card (GTC) application has been initiated. This is a 3 step process: online application, training and statement of understanding (SOU) "
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            " You will receive an email from Citibank prompting you to complete the application process (please check your junk folder if you do not see it in your inbox). Please review the following instructions to assist with the application: "
          ),
        ]),
        _c("li", [
          _vm._v(
            ' Citibank email will provide you with a "CitiDirect System Client Code" and "Passcode" '
          ),
        ]),
        _c("li", [
          _vm._v(
            ' Citibank email will provide you with a "Ref" number (bottom left) to be used in the "Verification Information" box '
          ),
        ]),
        _c("li", [
          _vm._v(
            " Shipping address-provide the address you would like your GTC card to be mailed "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Billing address-provide the address you would like to receive your statement "
          ),
        ]),
        _c("li", [
          _vm._v(" Your Supervisor is SSgt Janette Lopez, email address: "),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                rel: "noopener",
                href: "mailto:janette.lopez.2@us.af.mil",
              },
            },
            [_vm._v("janette.lopez.2@us.af.mil")]
          ),
          _vm._v(", work ph# "),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                rel: "noopener",
                href: "tel:210-652-6107",
              },
            },
            [_vm._v("210-652-6107")]
          ),
        ]),
        _c("li", [
          _vm._v(
            ' Select "I, as the cardholder, DO NOT AUTHORIZE the bank to obtain consumer reports on me" '
          ),
        ]),
        _c("li", [
          _vm._v(
            ' Ensure you activate "Go paperless" and put “N/A” in alternate employer info (if there isn\'t another employer for you at this time) '
          ),
        ]),
        _c("li", [
          _vm._v(
            " Once you receive your GTC, call the number provided to activate your card "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Complete required training for your GTC account: information attached (A-4) "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Review and sign SoU attached (A-5) **Send both documents back to the 340th workflow inbox ("
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                rel: "noopener",
                href: "mailto:340FTG.UFT.Workflow@us.af.mil",
              },
            },
            [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
          ),
          _vm._v(
            ") V/R, JANETTE M. LOPEZ, SSgt, USAF 340th Undergraduate Flying Training DSN: 487-6107 Comm: "
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                rel: "noopener",
                href: "tel:210-652-6107",
              },
            },
            [_vm._v("210-652-6107")]
          ),
        ]),
      ]),
      _c("p", [_vm._v("340th UFT Sharepoint (website):")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://randolph.eis.aetc.af.mil/340FTG/UFT/SitePages/Home.aspx",
            },
          },
          [_vm._v("340th UFT Sharepoint")]
        ),
      ]),
      _c("p", [_vm._v("Youtube: 340 FTG Public Affairs:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/channel/UCByIkPqNHL7e3oHjtWEYg4Q",
            },
          },
          [_vm._v("340 FTG Public Affairs")]
        ),
      ]),
      _c("p", [_vm._v("340 FTG WorkFlow Mailbox:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }