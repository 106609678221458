import { censusMiniTableConfig } from '@/components/StudentDashboard/MiniTables/Census/CensusMiniTableConfiguration';
import { gainsMiniTableConfig } from '@/components/StudentDashboard/MiniTables/Gains/GainsMiniTableConfiguration';
import { holdsMiniTableConfig } from '@/components/StudentDashboard/MiniTables/Holds/HoldsMiniTableConfiguration';
import { immediateActionMiniTableConfig } from '@/components/StudentDashboard/MiniTables/ImmediateAction/ImmediateActionMiniTableConfiguration';
import { inProMiniTableConfig } from '@/components/StudentDashboard/MiniTables/InPro/InProMiniTableConfiguration';
import { IMiniTableConfiguration } from '@/components/StudentDashboard/MiniTables/MiniTableConfiguration';
import { ordersEndMiniTableConfig } from '@/components/StudentDashboard/MiniTables/OrdersEnd/OrdersEndMiniTableConfiguration';
import { OTSMiniTableConfig } from '@/components/StudentDashboard/MiniTables/OTS/OTSMiniTableConfiguration';
import { outProMiniTableConfig } from '@/components/StudentDashboard/MiniTables/OutPro/OutProMiniTableConfiguration';
import { SECMiniTableConfig } from '@/components/StudentDashboard/MiniTables/SEC/SECMiniTableConfiguration';
import { startPayMiniTableConfig } from '@/components/StudentDashboard/MiniTables/StartPay/StartPayMiniTableConfiguration';
import { transitionMiniTableConfig } from '@/components/StudentDashboard/MiniTables/Transition/TransitionMiniTableConfiguration';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { Component as VueComponent } from 'vue';

export interface MiniTableComponent {
  component: VueComponent;
  title: string;
  key: string;
  on?: Record<string, unknown>;
  props?: Record<string, unknown>;
}

export interface TableDisplayConfiguration {
  title: string;
  key: string;
  showTable: boolean;
}

export const studentDashboardMiniTables: IMiniTableConfiguration<
  unknown,
  unknown,
  never,
  SearchDto<unknown, never>
>[] = [
  gainsMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  inProMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  OTSMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  immediateActionMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  startPayMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  outProMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  censusMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  SECMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  holdsMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  ordersEndMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >,
  transitionMiniTableConfig as IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >
];
