import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchSECDto } from '@/models/Dtos/searchSECDto';
import { SEC } from '@/models/Entities/MaterializedViews/SEC';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { SECTableData } from './SECTableData';

export const SECTableConfigBuilder: TableConfigBuilder<
  SECTableData,
  SEC,
  never,
  SearchSECDto
> = (): ITableConfiguration<SECTableData, SEC, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: SECTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Course Number',
        render: 'courseNumber',
        sortKey: 'courseNumber'
      },
      {
        columnTitle: 'Class Start',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: SECTableData) => {
            return {
              date: rowData.classStart
            };
          }
        },
        sortKey: 'classStart'
      }
    ]
  };
};
