
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import SocialSecurity from '@/components/Forms/Elements/SocialSecurityComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import ZipCodeInput from '@/components/Forms/Elements/ZipCodeInputComponent.vue';
import Regex from '@/models/Forms/Regex';
import ProfilePhotoComponent from '@/components/Applicant/ProfilePhotoComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<StudentVitalsComponent>({
  components: {
    DateSelector,
    PhoneInput,
    TextInput,
    SocialSecurity,
    ZipCodeInput,
    ProfilePhotoComponent
  }
})
export default class StudentVitalsComponent extends Vue {
  @Prop()
  readonly student!: Student;

  @Prop({ default: false })
  readonly readonly!: boolean;

  private initialDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18)
  );

  Regex = Regex;
}
