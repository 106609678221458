import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchOutProDto } from '@/models/Dtos/searchOutProDto';
import { OutPro } from '@/models/Entities/MaterializedViews/OutPro';
import { OutProTableData } from './OutProTableData';

export class OutProDataProvider extends TableDataProvider<
  OutProTableData,
  OutPro,
  SearchOutProDto
> {
  protected transformer = (t: OutPro): OutProTableData =>
    new OutProTableData(t);

  protected queryHandler = async (
    searchDto: SearchOutProDto
  ): Promise<SearchResponseDto<OutPro>> => {
    const results: SearchResponseDto<OutPro> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'outpro', query: searchDto, model: OutPro }
    );
    return results;
  };
}
