var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col max-h-screen" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "p-4 overflow-y-auto shadow-inner" },
        [
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "1288 (EDCSA)" },
            model: {
              value: _vm.student.outProcessingEdcsa1288,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingEdcsa1288", $$v)
              },
              expression: "student.outProcessingEdcsa1288",
            },
          }),
          _c(
            "button-component",
            {
              staticClass: "flex items-center justify-center mt-4 mb-4 h-9",
              attrs: { textOrBorderColor: "white", bg: "val-button-blue" },
              on: { click: _vm.openModal },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-4",
                attrs: { icon: ["fas", "envelope"] },
              }),
              _vm._v("Send Out Pro Package"),
            ],
            1
          ),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Leave Recon Requested" },
            model: {
              value: _vm.student.outProcessingLeavereconrequested,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingLeavereconrequested", $$v)
              },
              expression: "student.outProcessingLeavereconrequested",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Leave End Date" },
            model: {
              value: _vm.student.outProcessingLeaveenddate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingLeaveenddate", $$v)
              },
              expression: "student.outProcessingLeaveenddate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Leave Balance" },
            model: {
              value: _vm.student.outProcessingLeavebalance,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingLeavebalance", $$v)
              },
              expression: "student.outProcessingLeavebalance",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "AFF 988 Part 1" },
            model: {
              value: _vm.student.outProcessingAf988partone,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingAf988partone", $$v)
              },
              expression: "student.outProcessingAf988partone",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Final MOD Approved" },
            model: {
              value: _vm.student.outProcessingFinalmodapproved,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingFinalmodapproved", $$v)
              },
              expression: "student.outProcessingFinalmodapproved",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Survey Sent" },
            model: {
              value: _vm.student.outProcessingSurveysent,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingSurveysent", $$v)
              },
              expression: "student.outProcessingSurveysent",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Survey Received" },
            model: {
              value: _vm.student.outProcessingSurveyreceived,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingSurveyreceived", $$v)
              },
              expression: "student.outProcessingSurveyreceived",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-2",
            attrs: { label: "Final" },
            model: {
              value: _vm.student.outProcessingFinal,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingFinal", $$v)
              },
              expression: "student.outProcessingFinal",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              label: "Exit Reason",
              values: _vm.exitReasonDropdownOptions,
            },
            model: {
              value: _vm.student.outProcessingExitreason,
              callback: function ($$v) {
                _vm.$set(_vm.student, "outProcessingExitreason", $$v)
              },
              expression: "student.outProcessingExitreason",
            },
          }),
        ],
        1
      ),
      _c(
        "email-modal-component",
        {
          ref: "sendEmailModal",
          attrs: {
            title: "Out Pro Package",
            subject: "340 UFT Out Processing",
            to: _vm.student.contactInformationEmail,
          },
        },
        [_c("out-processing-package-email-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "font-semibold" }, [_vm._v("Student Services")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }