
import { Component, VModel, Vue } from 'vue-property-decorator';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<EdcsaComponent>({
  components: {
    TodaysDateButtonComponent
  }
})
export default class EdcsaComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;
}
