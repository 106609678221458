
import { Component, VModel, Vue } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { PocType } from '@/models/Entities/Student/Constants';
import { Student } from '@/models/Entities/Student/Student';

@Component<StudentRecruiterComponent>({
  components: {
    Radio,
    TextInput,
    PhoneInput
  }
})
export default class StudentRecruiterComponent extends Vue {
  @VModel()
  student!: Student;

  radioItems = getInputOptions(PocType);

  PocType = PocType;
}
