import { ELogActionType } from '@/enums/EActionTypes';
import { Log } from '@/models/Entities/Log';
import { ImmediateAction } from '@/models/Entities/MaterializedViews/ImmediateAction';
import { DisplayNameUtil } from '@/util/displayName';

export class ImmediateActionTableData {
  candidateId!: number;
  topic!: ELogActionType | null;
  personOfContact!: string | null;
  dateCreated!: Date | null;
  logId!: number | null;
  log!: Log;
  name!: string;

  constructor(immediateAction: ImmediateAction) {
    this.candidateId = immediateAction.candidateId;
    this.logId = immediateAction.logId;
    this.log = immediateAction.log;
    this.topic = immediateAction.topic;
    this.personOfContact = '';
    this.dateCreated = immediateAction.dateCreated;
    if (immediateAction.firstName && immediateAction.lastName) {
      this.name = DisplayNameUtil.flattenName(
        immediateAction.firstName.toUpperCase(),
        immediateAction.lastName.toUpperCase()
      );
    }
  }
}
