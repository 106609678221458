import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchSECDto } from '@/models/Dtos/searchSECDto';
import { SEC } from '@/models/Entities/MaterializedViews/SEC';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { SECTableConfigBuilder } from './SECConfigBuilder';
import { SECDataProvider } from './SECDataProvider';
import { SECTableData } from './SECTableData';

export const SECMiniTableConfig: IMiniTableConfiguration<
  SECTableData,
  SEC,
  never,
  SearchSECDto
> = {
  component: {
    component: MiniTable,
    title: 'SEC Clrnc (OBJ #4)Curry',
    key: 'SEC'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<SECTableData, SEC, never, SearchSECDto>(
      store,
      new SECDataProvider(store),
      {},
      { defaultSortProperty: 'lastName' },
      SECTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  }
};
