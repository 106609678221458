import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchOutProDto } from '@/models/Dtos/searchOutProDto';
import { OutPro } from '@/models/Entities/MaterializedViews/OutPro';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { OutProTableData } from './OutProTableData';

export const outProTableConfigBuilder: TableConfigBuilder<
  OutProTableData,
  OutPro,
  never,
  SearchOutProDto
> = (): ITableConfiguration<OutProTableData, OutPro, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: OutProTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Reason',
        render: 'reason'
      },
      {
        columnTitle: 'Sent 1288',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OutProTableData) => {
            return {
              date: rowData.sent1288
            };
          }
        },
        sortKey: 'sent1288'
      },
      {
        columnTitle: 'Rec 1288',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OutProTableData) => {
            return {
              date: rowData.received1288
            };
          }
        },
        sortKey: 'received1288'
      }
    ]
  };
};
