import { BaseCandidate } from '../../../../models/Entities/BaseCandidate';
import { OTS } from '../../../../models/Entities/MaterializedViews/OTS';
import { DisplayNameUtil } from '../../../../util/displayName';

export class OTSTableData {
  candidateId: number | null;
  candidate!: BaseCandidate;
  name!: string | null;
  graduationDate!: Date | null;
  classStartDate!: Date | null;
  wings: Date | null;
  onSite: Date | null;
  classNumber: string | null;

  constructor(ots: OTS) {
    this.candidateId = ots.candidateId;
    this.classNumber = ots.classNumber;
    this.classStartDate = ots.classStartDate;
    this.graduationDate = ots.graduationDate;
    this.wings = ots.wings;
    this.onSite = ots.onSite;
    if (ots.firstName && ots.lastName) {
      this.name = DisplayNameUtil.flattenName(
        ots.firstName.toUpperCase(),
        ots.lastName.toUpperCase()
      );
    }
    this.candidate = ots.candidate;
  }
}
