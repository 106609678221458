import { Type, Transform, plainToClass } from 'class-transformer';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { BaseCandidate } from '../BaseCandidate';
import { ExitReason } from '../Student/Constants';

export class OutPro {
  reason!: ExitReason | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  edcsa!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  sent1288!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  received1288!: Date | null;
  firstName!: string | null;
  lastName!: string | null;
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;

  constructor(partial?: Partial<OutPro>) {
    if (partial) {
      Object.assign(this, plainToClass(OutPro, partial));
    }
  }
}
