
import { Component, VModel, Vue } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { DropdownOptions } from '@/models/Forms/FormOption';
import { GroupInfo } from '@/models/Entities/Student/Constants';
import { Student } from '@/models/Entities/Student/Student';
import { getInputOptions } from '@/util/getInputOptions';

@Component<StudentGroupInfoComponent>({
  components: {
    DropdownComponent
  }
})
export default class StudentGroupInfoComponent extends Vue {
  @VModel({ required: true })
  student!: Student;

  get dropdownItems(): DropdownOptions[] {
    return getInputOptions(GroupInfo);
  }
}
