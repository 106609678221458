
import { Component, VModel, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Checkbox from '@/components/Forms/Elements/CheckboxComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import EmailModalComponent from '@/components/Modals/Email/EmailModalComponent.vue';
import InProcessingEmailComponent from '@/components/Email/InProcessingEmailComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<FinalDatesColumnComponent>({
  components: {
    ButtonComponent,
    Checkbox,
    DropdownComponent,
    EmailModalComponent,
    IconComponent,
    InProcessingEmailComponent,
    TextInput,
    TodaysDateButtonComponent
  }
})
export default class FinalDatesColumnComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;

  $refs!: {
    emailModal: EmailModalComponent;
  };

  handleEmailInprocessingClick(): void {
    this.$refs.emailModal.open();
  }
}
