import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { SEC } from '@/models/Entities/MaterializedViews/SEC';
import { DisplayNameUtil } from '@/util/displayName';

export class SECTableData {
  courseNumber!: string | null;
  classStart!: Date | null;
  candidateId!: number;
  candidate!: BaseCandidate;
  name!: string;

  constructor(sec: SEC) {
    this.courseNumber = sec.courseNumber;
    this.classStart = sec.classStart;
    if (sec.firstName && sec.lastName) {
      this.name = DisplayNameUtil.flattenName(
        sec.firstName.toUpperCase(),
        sec.lastName.toUpperCase()
      );
    }
    this.candidateId = sec.candidateId;
    this.candidate = sec.candidate;
  }
}
