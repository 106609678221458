
import { Component, VModel, Vue } from 'vue-property-decorator';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EExitReasonTypes } from '@/enums/EExitReasonTypes';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import EmailModalComponent from '@/components/Modals/Email/EmailModalComponent.vue';
import OutProcessingPackageEmailComponent from '@/components/Email/OutProcessingPackageEmailComponent.vue';

@Component<StudentServicesComponent>({
  components: {
    Radio,
    TextInput,
    TodaysDateButtonComponent,
    DropdownComponent,
    ButtonComponent,
    FontAwesomeIcon,
    EmailModalComponent,
    OutProcessingPackageEmailComponent
  }
})
export default class StudentServicesComponent extends Vue {
  @VModel({ default: () => [] })
  student!: Student;

  $refs!: {
    sendEmailModal: EmailModalComponent;
  };

  exitReasonDropdownOptions = getInputOptions(EExitReasonTypes);

  openModal(): void {
    this.$refs.sendEmailModal.open();
    return;
  }
}
