var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-container" }, [
      _c("h3", [_vm._v("Pre Cert Email")]),
      _c("p", { staticClass: "font-semibold" }, [
        _vm._v(" PLEASE DISREGARD EMAIL IF ACTION HAS BEEN COMPLETED "),
      ]),
      _c("p", [
        _vm._v(
          " To minimize delays in your pay, please review the YouTube videos below for instructions on how to post and pre certify your AF 938. Once complete, please scan (camscan) your documents and submit to the 340 UFT workflow inbox for processing ("
        ),
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
        ),
        _vm._v("). "),
      ]),
      _c("p", [
        _vm._v(" Please login to your AROWS-R account "),
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://arowsr.afrc.af.mil/arows-r",
            },
          },
          [_vm._v("https://arowsr.afrc.af.mil/arows-r/")]
        ),
        _vm._v(". to download your orders. "),
      ]),
      _c("p", [_vm._v("Post Certify Order:")]),
      _c("p", [_vm._v("Post Certify Order, Stop Pay (340 FTG/UFT)")]),
      _c("p", [
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/watch?v=uLFnXpTYfWw",
            },
          },
          [_vm._v("https://www.youtube.com/watch?v=uLFnXpTYfWw")]
        ),
      ]),
      _c("p", [_vm._v("Certify Order:")]),
      _c("p", [_vm._v("Pre-Certify Order, Start Pay (340 FTG/UFT)")]),
      _c("p", [
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/watch?v=VqSzwQUJz_s",
            },
          },
          [_vm._v("https://www.youtube.com/watch?v=VqSzwQUJz_s")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }