import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchTransitionDto } from '@/models/Dtos/searchTransitionDto';
import { Transition } from '@/models/Entities/MaterializedViews/Transition';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { transitionTableConfigBuilder } from './TransitionConfigBuilder';
import { TransitionDataProvider } from './TransitionDataProvider';
import { TransitionTableData } from './TransitionTableData';

export const transitionMiniTableConfig: IMiniTableConfiguration<
  TransitionTableData,
  Transition,
  never,
  SearchTransitionDto
> = {
  component: {
    component: MiniTable,
    title: 'Transition',
    key: 'Transition'
  },
  emptyTableMessage: 'No Candidates',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      TransitionTableData,
      Transition,
      never,
      SearchTransitionDto
    >(
      store,
      new TransitionDataProvider(store),
      { relations: ['candidate'] },
      { defaultSortProperty: 'lastName' },
      transitionTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  }
};
