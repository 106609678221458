
import { Component, VModel, Vue } from 'vue-property-decorator';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import DutyStatusColumnComponent from './DutyStatusColumnComponent.vue';

@Component<StudentDutyStatusComponent>({
  components: {
    Radio,
    TextInput,
    DutyStatusColumnComponent
  }
})
export default class StudentDutyStatusComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;

  yesNoOptions = yesNoOptions;
}
