import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchHoldDto } from '@/models/Dtos/searchHoldDto';
import { Hold } from '@/models/Entities/MaterializedViews/Hold';
import { HoldsTableData } from './HoldsTableData';

export class HoldsDataProvider extends TableDataProvider<
  HoldsTableData,
  Hold,
  SearchHoldDto
> {
  protected transformer = (t: Hold): HoldsTableData => new HoldsTableData(t);

  protected queryHandler = async (
    searchDto: SearchHoldDto
  ): Promise<SearchResponseDto<Hold>> => {
    const results: SearchResponseDto<Hold> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'hold', query: searchDto, model: Hold }
    );
    return results;
  };
}
