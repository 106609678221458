import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchHoldDto } from '@/models/Dtos/searchHoldDto';
import { Hold } from '@/models/Entities/MaterializedViews/Hold';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { holdsTableConfigBuilder } from './HoldsConfigBuilder';
import { HoldsDataProvider } from './HoldsDataProvider';
import { HoldsTableData } from './HoldsTableData';

export const holdsMiniTableConfig: IMiniTableConfiguration<
  HoldsTableData,
  Hold,
  never,
  SearchHoldDto
> = {
  component: {
    component: MiniTable,
    title: 'Holds (OBJ #4) McMillan / Pasierb',
    key: 'Holds'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<HoldsTableData, Hold, never, SearchHoldDto>(
      store,
      new HoldsDataProvider(store),
      // TODO: find place to set default sort direction and set to descending
      {},
      { defaultSortProperty: 'orderEndDate' },
      holdsTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
  }
};
