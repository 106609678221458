
import { Component, VModel, Vue } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<PreGainsComponent>({
  components: {
    TextInput,
    TodaysDateButtonComponent,
    DropdownComponent
  }
})
export default class PreGainsComponent extends Vue {
  //Change this to VModel/injection when we shift to using save button instead
  @VModel({ required: true })
  student!: Student;
}
