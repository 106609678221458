
import { Component, VModel, Vue } from 'vue-property-decorator';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EExitReasonTypes } from '@/enums/EExitReasonTypes';

@Component<PersonnelComponent>({
  components: {
    Radio,
    TextInput,
    TodaysDateButtonComponent
  }
})
export default class PersonnelComponent extends Vue {
  @VModel({ default: () => [] })
  student!: Student;

  exitReasonDropdownOptions = getInputOptions(EExitReasonTypes);
}
