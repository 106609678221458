var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col max-h-screen" },
    [
      _vm._m(0),
      _c("todays-date-button-component", {
        staticClass: "px-6 mt-2.5",
        attrs: { "data-cy": "edcsa", alignment: "top" },
        model: {
          value: _vm.student.gainsEdcsa,
          callback: function ($$v) {
            _vm.$set(_vm.student, "gainsEdcsa", $$v)
          },
          expression: "student.gainsEdcsa",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "flyout-header" }, [_vm._v("EDCSA")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }