import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchImmediateActionDto } from '@/models/Dtos/searchImmediateActionDto';
import { ImmediateAction } from '@/models/Entities/MaterializedViews/ImmediateAction';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { immediateActionTableConfigBuilder } from './ImmediateActionConfigBuilder';
import { ImmediateActionDataProvider } from './ImmediateActionDataProvider';
import { ImmediateActionTableData } from './ImmediateActionTableData';

export const immediateActionMiniTableConfig: IMiniTableConfiguration<
  ImmediateActionTableData,
  ImmediateAction,
  never,
  SearchImmediateActionDto
> = {
  component: {
    title: 'Immediate Action',
    key: 'Immediate Action',
    component: MiniTable
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      ImmediateActionTableData,
      ImmediateAction,
      never,
      SearchImmediateActionDto
    >(
      store,
      new ImmediateActionDataProvider(store),
      {
        relations: ['log']
      },
      { defaultSortProperty: 'dateCreated' },
      immediateActionTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  }
};
