var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col max-h-screen" },
    [
      _c("div", { staticClass: "flex-shrink-0 border-b p-7" }),
      _c(
        "div",
        { staticClass: "p-4 overflow-y-auto shadow-inner" },
        [
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "cbtTransDate",
              label: "CBT Trans",
              alignment: "top",
            },
            model: {
              value: _vm.student.inProcessingCbttransdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingCbttransdate", $$v)
              },
              expression: "student.inProcessingCbttransdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "poaDate", label: "POA" },
            model: {
              value: _vm.student.inProcessingPoadate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingPoadate", $$v)
              },
              expression: "student.inProcessingPoadate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "willWorkDate", label: "Will Work" },
            model: {
              value: _vm.student.inProcessingWillworkdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingWillworkdate", $$v)
              },
              expression: "student.inProcessingWillworkdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "moveMilitaryBaseDate", label: "Move Mil" },
            model: {
              value: _vm.student.inProcessingMovemilitarybasedate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingMovemilitarybasedate", $$v)
              },
              expression: "student.inProcessingMovemilitarybasedate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "sgliDate", label: "SGLI Sent" },
            model: {
              value: _vm.student.inProcessingSglidate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingSglidate", $$v)
              },
              expression: "student.inProcessingSglidate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "sf86Date", label: "SF86" },
            model: {
              value: _vm.student.inProcessingSf86date,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingSf86date", $$v)
              },
              expression: "student.inProcessingSf86date",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "DISSReviewDate", label: "DISS Review" },
            model: {
              value: _vm.student.inProcessingDissreviewdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingDissreviewdate", $$v)
              },
              expression: "student.inProcessingDissreviewdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "ndaDate", label: "NDA" },
            model: {
              value: _vm.student.inProcessingNdadate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingNdadate", $$v)
              },
              expression: "student.inProcessingNdadate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "fingerPrintsDate", label: "Fingerprints" },
            model: {
              value: _vm.student.inProcessingFingerprintsdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingFingerprintsdate", $$v)
              },
              expression: "student.inProcessingFingerprintsdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "uniformLettersDate", label: "Uniform Ltrs" },
            model: {
              value: _vm.student.inProcessingUniformlettersdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingUniformlettersdate", $$v)
              },
              expression: "student.inProcessingUniformlettersdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "alterationsDate", label: "Alterations" },
            model: {
              value: _vm.student.inProcessingAlterationsdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingAlterationsdate", $$v)
              },
              expression: "student.inProcessingAlterationsdate",
            },
          }),
          _c(
            "button-component",
            {
              staticClass: "mb-6",
              attrs: {
                "data-cy": "handleEmailInprocessingClick",
                color: "white",
                bg: "blue-500",
              },
              on: { click: _vm.handleEmailInprocessingClick },
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("icon-component", {
                    staticClass: "flex-grow-0 flex-shrink-0 mr-2",
                    attrs: {
                      fill: "white",
                      stroke: "white",
                      width: 16,
                      height: 10,
                      name: "email",
                    },
                  }),
                  _c("span", [_vm._v("Pre-Inpro E-mail")]),
                ],
                1
              ),
            ]
          ),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "inProcessingCompletedDate",
              label: "Inpro Completed",
              alignment: "bottom",
            },
            model: {
              value: _vm.student.inProcessingInprocessingcompleteddate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.student,
                  "inProcessingInprocessingcompleteddate",
                  $$v
                )
              },
              expression: "student.inProcessingInprocessingcompleteddate",
            },
          }),
        ],
        1
      ),
      _c(
        "email-modal-component",
        {
          ref: "emailModal",
          attrs: {
            "data-cy": "emailComponent",
            to: _vm.student.contactInformationEmail,
            subject: "340 FTG/UFT In-processing",
          },
        },
        [_c("in-processing-email-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }