
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { Student } from '@/models/Entities/Student/Student';
import StudentSelectionFlyoutComponent from '@/components/Flyout/StudentSelectionFlyoutComponent.vue';
import StartPayEmailComponent from '@/components/Email/StartPayEmailComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import ProfilePhotoComponent from '@/components/Applicant/ProfilePhotoComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import CopyToClipboardComponent from '@/components/CopyToClipboard/CopyToClipboardComponent.vue';
import CopyHtmlToClipboardComponent from '@/components/CopyToClipboard/CopyHTMLToClipboardComponent.vue';
import UpdateStudentMixin from '@/mixins/updateStudentMixin';

@Component<StartPayFlyoutComponent>({
  components: {
    CopyHtmlToClipboardComponent,
    CopyToClipboardComponent,
    IconComponent,
    ProfilePhotoComponent,
    StartPayEmailComponent,
    StudentSelectionFlyoutComponent,
    TextInput
  }
})
// TODO: check methods are working after students are passed and can render
export default class StartPayFlyoutComponent extends Mixins(
  UpdateStudentMixin,
  Vue
) {
  students!: Student[];
  show = false;
  activeStudentIndex = 0;

  resolve: () => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  public open(options: Student[]): Promise<void> {
    this.show = true;
    this.students = options ?? [];
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  close(): void {
    this.show = false;
    this.reject();
  }

  handleSelectStudent(index: number): void {
    this.activeStudentIndex = index;
  }

  get activeStudent(): Student {
    return this.students[this.activeStudentIndex];
  }

  get photoLocation(): string {
    return this.activeStudent.documentsProfilepicturelocation ?? '';
  }

  get subject(): string {
    if (!this.activeStudent || !this.activeStudent.startPayTrackingnumber) {
      return `Pre Certify Order Reminder`;
    }
    return `Pre Certify Order Reminder For Order Tracking Number: ${this.activeStudent.startPayTrackingnumber}`;
  }

  get mailTo(): string {
    return `mailto:${this.activeStudent.contactInformationEmail}?subject=${this.subject}`;
  }

  handleCreateEmailClick(): void {
    this.activeStudent.startPayEmailsentdate = new Date();
  }
}
