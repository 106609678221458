var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition",
    { attrs: { name: "slide-fade" } },
    [
      _vm.show
        ? _c(
            "student-selection-flyout-component",
            {
              attrs: { title: "Start Pay", students: _vm.students },
              on: {
                save: function ($event) {
                  return _vm.updateActiveStudent(_vm.activeStudent)
                },
                cancel: _vm.close,
                selectStudent: _vm.handleSelectStudent,
              },
            },
            [
              _vm.activeStudent
                ? _c(
                    "div",
                    { staticClass: "flex flex-col w-full h-full xl:flex-row" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-shrink-0 w-full p-8 overflow-auto border-b border-r bg-gray-50 xl:h-full xl:w-96 xl:border-b-0",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-2xl whitespace-nowrap" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.activeStudent.fullName) + " "
                              ),
                            ]
                          ),
                          _c("profile-photo-component", {
                            staticClass: "my-4",
                            attrs: { photoLocation: _vm.photoLocation },
                          }),
                          _c("div", { staticClass: "mt-4" }, [_vm._v("Email")]),
                          _c("copy-to-clipboard-component", {
                            attrs: {
                              "data-cy": "copyEmailToClipboardButton",
                              content:
                                _vm.activeStudent.contactInformationEmail,
                            },
                          }),
                          _c("div", { staticClass: "mt-4" }, [
                            _vm._v("Subject"),
                          ]),
                          _c("copy-to-clipboard-component", {
                            attrs: {
                              "data-cy": "copyTrackingNoToClipboardButton",
                              content: _vm.subject,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "copy-html-to-clipboard-component",
                        {
                          staticClass: "h-full overflow-auto",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "action-buttons-left",
                                fn: function () {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "flex items-center px-4 text-sm text-blue-500 no-underline border border-blue-500 rounded",
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.mailTo,
                                        },
                                        on: {
                                          click: _vm.handleCreateEmailClick,
                                        },
                                      },
                                      [_vm._v("Create Email")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4017190115
                          ),
                        },
                        [_c("start-pay-email-component")],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }