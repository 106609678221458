var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 bottom-0 right-0 z-40 h-full overflow-y-scroll w-full md:ml-auto md:w-11/12 max-w-screen-2xl bg-white border-l shadow-2xl",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }