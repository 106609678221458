var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.student
    ? _c("div", { staticClass: "flex flex-col max-h-screen" }, [
        _c(
          "div",
          { staticClass: "flex-shrink-0 px-6 py-4" },
          [
            _c("div", { staticClass: "mb-4 font-semibold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.student.identificationInformationLastname) +
                  ", " +
                  _vm._s(_vm.student.identificationInformationFirstname) +
                  " "
              ),
            ]),
            _c("profile-photo-component", {
              attrs: {
                photoLocation: _vm.student.documentsProfilepicturelocation,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "px-6 py-4 overflow-y-auto" },
          [
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "First name" },
              model: {
                value: _vm.student.identificationInformationFirstname,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.student,
                    "identificationInformationFirstname",
                    $$v
                  )
                },
                expression: "student.identificationInformationFirstname",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Middle name" },
              model: {
                value: _vm.student.identificationInformationMiddlename,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.student,
                    "identificationInformationMiddlename",
                    $$v
                  )
                },
                expression: "student.identificationInformationMiddlename",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Last name" },
              model: {
                value: _vm.student.identificationInformationLastname,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.student,
                    "identificationInformationLastname",
                    $$v
                  )
                },
                expression: "student.identificationInformationLastname",
              },
            }),
            _c("date-selector", {
              staticClass: "mb-6",
              attrs: {
                readonly: _vm.readonly,
                yearsForward: 0,
                initialDate: _vm.initialDate,
                label: "Date of birth",
              },
              model: {
                value: _vm.student.identificationInformationDateofbirth,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.student,
                    "identificationInformationDateofbirth",
                    $$v
                  )
                },
                expression: "student.identificationInformationDateofbirth",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: {
                readonly: _vm.readonly,
                regex: _vm.Regex.email,
                label: "Email",
              },
              model: {
                value: _vm.student.contactInformationEmail,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationEmail", $$v)
                },
                expression: "student.contactInformationEmail",
              },
            }),
            _c("phone-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Home phone" },
              model: {
                value: _vm.student.contactInformationHomephone,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationHomephone", $$v)
                },
                expression: "student.contactInformationHomephone",
              },
            }),
            _c("social-security", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "SSN" },
              model: {
                value:
                  _vm.student.identificationInformationSocialsecuritynumber,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.student,
                    "identificationInformationSocialsecuritynumber",
                    $$v
                  )
                },
                expression:
                  "student.identificationInformationSocialsecuritynumber",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Address 1" },
              model: {
                value: _vm.student.contactInformationAddress1,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationAddress1", $$v)
                },
                expression: "student.contactInformationAddress1",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Address 2" },
              model: {
                value: _vm.student.contactInformationAddress2,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationAddress2", $$v)
                },
                expression: "student.contactInformationAddress2",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "City" },
              model: {
                value: _vm.student.contactInformationCity,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationCity", $$v)
                },
                expression: "student.contactInformationCity",
              },
            }),
            _c("text-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "State" },
              model: {
                value: _vm.student.contactInformationState,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationState", $$v)
                },
                expression: "student.contactInformationState",
              },
            }),
            _c("zip-code-input", {
              staticClass: "mb-6",
              attrs: { readonly: _vm.readonly, label: "Zip" },
              model: {
                value: _vm.student.contactInformationZipcode,
                callback: function ($$v) {
                  _vm.$set(_vm.student, "contactInformationZipcode", $$v)
                },
                expression: "student.contactInformationZipcode",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }