
import { Component, VModel, Vue } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import { getInputOptions } from '@/util/getInputOptions';
import { EGainsPriority } from '@/enums';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';

@Component<PostGainsComponent>({
  components: {
    TextInput,
    TodaysDateButtonComponent,
    DropdownComponent
  }
})
export default class PostGainsComponent extends Vue {
  @VModel({ required: true })
  student!: Student;

  gainsPriorityOptions = getInputOptions(EGainsPriority);
}
