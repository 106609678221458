var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "p-4 bg-gray-100" },
    [
      _c("div", { staticClass: "flex items-center mb-4" }, [
        _c("div", { staticClass: "ml-2 text-2xl font-semibold" }, [
          _c(
            "button",
            { staticClass: "font-semibold", on: { click: _vm.onHeadingClick } },
            [_vm._v(" 340th Dashboard ")]
          ),
          _vm.activeTableName
            ? _c("span", { staticClass: "pl-2" }, [
                _vm._v("/ " + _vm._s(_vm.activeTableName) + " "),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "flex ml-auto gap-x-4" },
          [
            _c("action-menu-component", {
              attrs: {
                showNumberSelected: false,
                items: _vm.administrator,
                title: "Administrator",
              },
              on: { itemClick: _vm.handleSelectAction },
            }),
            _c("action-menu-component", {
              attrs: {
                showNumberSelected: false,
                items: _vm.actions,
                title: "Action",
              },
              on: { itemClick: _vm.handleSelectAction },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          class: {
            "grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3":
              !_vm.activeTableName,
          },
        },
        _vm._l(_vm.filteredMiniTables, function (miniTableConfig, index) {
          return _c(
            miniTableConfig.component.component,
            _vm._g(
              _vm._b(
                {
                  key: index,
                  tag: "component",
                  attrs: {
                    config: miniTableConfig,
                    title: miniTableConfig.component.title,
                    tableSearchService: miniTableConfig.tableSearchService,
                  },
                  on: { titleClick: _vm.onTitleClick },
                },
                "component",
                miniTableConfig.component.props,
                false
              ),
              miniTableConfig.component.on
            )
          )
        }),
        1
      ),
      _c("flyout-mounter-component"),
      _c("select-file-to-upload-component", {
        ref: "fileUpload",
        staticClass: "hidden",
        on: { importData: _vm.importData },
      }),
      _c("item-selection-modal", {
        ref: "miniTableConfigurationModal",
        attrs: {
          confirmButtonText: "Save Changes",
          title: "Configure Dashboard",
        },
      }),
      _c("caution-modal-component", {
        ref: "cautionModalComponent",
        attrs: {
          icon: ["fas", "triangle-exclamation"],
          iconColor: _vm.iconColors.RED,
          title: "Warning",
          continueText: "Delete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }