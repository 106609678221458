
import { Component, VModel, Vue } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import Checkbox from '@/components/Forms/Elements/CheckboxComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<LettersColumnComponent>({
  components: {
    DropdownComponent,
    Checkbox,
    TodaysDateButtonComponent
  }
})
export default class LettersColumnComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;
}
