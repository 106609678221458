import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Transition } from '@/models/Entities/MaterializedViews/Transition';
import { DisplayNameUtil } from '@/util/displayName';

export class TransitionTableData {
  student: BaseCandidate;
  previousCourseEndDate?: Date | null;
  nextCourseStartDate?: Date | null;
  transitionDays?: number;
  name: string;

  constructor(transition: Transition) {
    this.student = transition.candidate;
    this.previousCourseEndDate = transition.previousCourseEndDate;
    this.nextCourseStartDate = transition.nextCourseStartDate;
    this.transitionDays = transition.daysBetweenLastCourseAndNextCourse;
    this.name = DisplayNameUtil.flattenCandidateName(this.student);
  }
}
