var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal-component", { on: { close: _vm.cancel } }, [
        _vm.title
          ? _c(
              "div",
              { staticClass: "flex justify-between p-8 mt-1" },
              [
                _c(
                  "div",
                  { staticClass: "mt-1 ml-4 text-2xl font-bold capitalize" },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _c("icon-component", {
                  staticClass: "cursor-pointer mt-2 mr-2",
                  attrs: { name: "close", width: 15, height: 15 },
                  on: { click: _vm.cancel },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.model.length > 0
          ? _c(
              "div",
              { staticClass: "flex grid grid-cols-2 mx-8 mb-8" },
              _vm._l(_vm.model, function (item, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "flex items-center p-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item[_vm.valueIndex],
                          expression: "item[valueIndex]",
                        },
                      ],
                      staticClass: "w-5 h-5 ml-2",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(item[_vm.valueIndex])
                          ? _vm._i(item[_vm.valueIndex], null) > -1
                          : item[_vm.valueIndex],
                      },
                      on: {
                        change: function ($event) {
                          var $$a = item[_vm.valueIndex],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  item,
                                  _vm.valueIndex,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  item,
                                  _vm.valueIndex,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(item, _vm.valueIndex, $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { staticClass: "ml-2 text-base" }, [
                      _vm._v(_vm._s(item[_vm.textIndex])),
                    ]),
                  ]),
                ])
              }),
              0
            )
          : _c("p", { staticClass: "ml-4 px-8 pb-8" }, [
              _vm._v("There is no data to display"),
            ]),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "ml-auto mr-8 mb-8 pb-1" },
            [
              _c(
                "button-component",
                {
                  attrs: { "data-cy": "cancelButton" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.closeButtonText))]
              ),
              _vm.model.length > 0
                ? _c(
                    "button-component",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        "data-cy": "confirmButton",
                        bg: "val-button-blue",
                        textOrBorderColor: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirm(_vm.model)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.confirmButtonText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }