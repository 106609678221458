var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "h-full p-4 overflow-y-auto shadow-inner" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "schedulePostOtsCallCompletionDate",
            label: "Schedule Post OTS Call",
            alignment: "top",
          },
          model: {
            value:
              _vm.student
                .officerTrainingSchoolSchedulepostotscallcompletiondate,
            callback: function ($$v) {
              _vm.$set(
                _vm.student,
                "officerTrainingSchoolSchedulepostotscallcompletiondate",
                $$v
              )
            },
            expression:
              "student.officerTrainingSchoolSchedulepostotscallcompletiondate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "degreeAuditDate", label: "Degree Audit" },
          model: {
            value: _vm.student.officerTrainingSchoolDegreeauditdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "officerTrainingSchoolDegreeauditdate", $$v)
            },
            expression: "student.officerTrainingSchoolDegreeauditdate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "font-semibold" }, [_vm._v("During OTS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }