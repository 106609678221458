var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-6 pb-4 overflow-y-auto" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "sharedDriveDate",
            alignment: "top",
            label: "Shared drive folder",
          },
          model: {
            value: _vm.student.gainsShareddrivefolderdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsShareddrivefolderdate", $$v)
            },
            expression: "student.gainsShareddrivefolderdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "vitalsCommCheckDate",
            label: "Vitals comm. check",
          },
          model: {
            value: _vm.student.gainsVitalscommcheckdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsVitalscommcheckdate", $$v)
            },
            expression: "student.gainsVitalscommcheckdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "medicalClearanceDate",
            label: "Medical Clearance",
            link: "https://pepp.sso.cce.af.mil/portal/pepp/login/login.cfm",
          },
          model: {
            value: _vm.student.gainsMedicalclearancedate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsMedicalclearancedate", $$v)
            },
            expression: "student.gainsMedicalclearancedate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "securityCheckDate",
            label: "Security",
            link: "https://www.dcsa.mil/is/diss/",
          },
          model: {
            value: _vm.student.gainsSecuritycheckdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsSecuritycheckdate", $$v)
            },
            expression: "student.gainsSecuritycheckdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "1288SentDate", label: "1288 sent" },
          model: {
            value: _vm.student.gains1288sentdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gains1288sentdate", $$v)
            },
            expression: "student.gains1288sentdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "af1288Received",
            label: "AF 1288 Received",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.gainsAf1288received,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsAf1288received", $$v)
            },
            expression: "student.gainsAf1288received",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "milpdsGainProjectionDate",
            label: "MILPDS gain projection",
            link: "https://milpds-prod.csd.disa.mil/",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.gainsMilpdsgainprojectiondate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsMilpdsgainprojectiondate", $$v)
            },
            expression: "student.gainsMilpdsgainprojectiondate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "milpdsPasCodeConfirmationate",
            label: "MILPDS PAS code confirm",
            link: "https://milpds-prod.csd.disa.mil/",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.gainsMilpdspascodeconfirmationate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsMilpdspascodeconfirmationate", $$v)
            },
            expression: "student.gainsMilpdspascodeconfirmationate",
          },
        }),
        _c("dropdown-component", {
          attrs: { "data-cy": "sereCompleted", label: "SERE Complete" },
          model: {
            value: _vm.student.sereCompleted,
            callback: function ($$v) {
              _vm.$set(_vm.student, "sereCompleted", $$v)
            },
            expression: "student.sereCompleted",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "flyout-header" }, [_vm._v("Pre Gains")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }