import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { OTSTableData } from './OTSTableData';
import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import { OTS } from '@/models/Entities/MaterializedViews/OTS';
import { SearchOTSDto } from '@/models/Dtos/searchOTSDto';

export const OTSTableConfigBuilder: TableConfigBuilder<
  OTSTableData,
  OTS,
  never,
  SearchOTSDto
> = (): ITableConfiguration<OTSTableData, OTS, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: OTSTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Class',
        render: 'classNumber'
      },
      {
        columnTitle: 'Class Start Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OTSTableData) => {
            return {
              date: rowData.classStartDate
            };
          }
        }
      },
      {
        columnTitle: 'Graduation Date',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OTSTableData) => {
            return {
              date: rowData.graduationDate
            };
          }
        }
      },
      {
        columnTitle: 'Wings',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OTSTableData) => {
            return {
              date: rowData.wings
            };
          }
        }
      },
      {
        columnTitle: 'On Site',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OTSTableData) => {
            return {
              date: rowData.onSite
            };
          }
        }
      }
    ]
  };
};
