import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';

export class SEC {
  firstName!: string | null;
  lastName!: string | null;
  courseNumber!: string | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  classStart!: Date | null;
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;

  constructor(partial?: Partial<SEC>) {
    if (partial) {
      Object.assign(this, plainToClass(SEC, partial));
    }
  }
}
