import { Store } from 'vuex';
import { FlyoutMounter } from '@/components/Flyout/FlyoutMounter';
import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { RootState } from '@/store/types';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { startPayTableConfigBuilder } from './StartPayConfigBuilder';
import { StartPayDataProvider } from './StartPayDataProvider';
import { StartPayTableData } from './StartPayTableData';
import { StartPay } from '../../../../models/Entities/MaterializedViews/StartPay';
import { SearchStartPayDto } from '../../../../models/Dtos/searchStartPayDto';

export const startPayMiniTableConfig: IMiniTableConfiguration<
  StartPayTableData,
  StartPay,
  never,
  SearchStartPayDto
> = {
  component: {
    component: MiniTable,
    title: 'Start Pay Lovci',
    key: 'Start Pay'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      StartPayTableData,
      StartPay,
      never,
      SearchStartPayDto
    >(
      store,
      new StartPayDataProvider(store),
      { relations: ['candidate'] },
      { defaultSortProperty: 'modEndDate' },
      startPayTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  },
  flyOutOptions: {
    cyTag: 'tag',
    openFlyoutHandler: (selectedData: StartPayTableData[]) => {
      const students = selectedData.map((data) => data.candidate);
      return FlyoutMounter.instance.refs.startpayFlyout.open(students);
    }
  }
};
