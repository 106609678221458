
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BaseFlyoutComponent from '@/components/Flyout/BaseFlyoutComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<StudentSelectionFlyoutComponent>({
  components: {
    BaseFlyoutComponent,
    ButtonComponent
  }
})
export default class StudentSelectionFlyoutComponent extends Vue {
  @Prop({
    default: () => []
  })
  students!: Student[];

  @Prop({ default: '' })
  title!: string;

  activeStudentIndex = 0;

  handleSelectStudent(index: number): void {
    this.activeStudentIndex = index;
    this.emitSelection();
  }

  handleNextStudent(): void {
    this.activeStudentIndex++;
    if (this.activeStudentIndex >= this.students.length) {
      this.activeStudentIndex = 0;
    }
    this.emitSelection();
  }

  emitSelection(): void {
    this.$emit('selectStudent', this.activeStudentIndex);
  }

  /* Emit up to the parent component the initial selection */
  mounted(): void {
    this.emitSelection();
  }
}
