import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { Student } from '@/models/Entities/Student/Student';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { DisplayNameUtil } from '@/util/displayName';

export class OrdersEndTableData {
  id: number;
  end?: Date | null;
  daysLeft?: number;
  name: string;
  student: Student;

  constructor(ordersEnd: OrdersEnd) {
    const student = ordersEnd.candidate;
    this.student = student;
    this.id = student.id;
    this.end = removeTimeZoneOffset(new Date(ordersEnd.orderEndDate));
    if (this.end) {
      const diff = this.end.getTime() - new Date().getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.daysLeft = diffDays;
    }
    this.name = DisplayNameUtil.flattenCandidateName(student);
  }
}
