import { FlyoutMounter } from '@/components/Flyout/FlyoutMounter';
import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { gainsTableConfigBuilder } from './GainsConfigBuilder';
import { GainsDataProvider } from './GainsDataProvider';
import { GainsTableData } from './GainsTableData';

export const gainsMiniTableConfig: IMiniTableConfiguration<
  GainsTableData,
  Gains,
  never,
  SearchGainsDto
> = {
  component: {
    component: MiniTable,
    title: 'Gains (OBJ #3) Lopez/Trachy',
    key: 'Gains'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<GainsTableData, Gains, never, SearchGainsDto>(
      store,
      new GainsDataProvider(store),
      {
        relations: ['candidate']
      },
      { defaultSortProperty: 'studentUftBoardDateSelected' },
      gainsTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  },
  flyOutOptions: {
    cyTag: 'tag',
    openFlyoutHandler: (selectedData: GainsTableData[]) => {
      const students = selectedData.map((data) => data.candidate);
      return FlyoutMounter.instance.refs.gainsFlyout.open({
        students: students
      });
    }
  }
};
