var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen mt-5" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-6 overflow-y-auto" },
      [
        _c("dropdown-component", {
          attrs: { values: _vm.dropdownItems },
          model: {
            value: _vm.student.gainsGroupinfo,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsGroupinfo", $$v)
            },
            expression: "student.gainsGroupinfo",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "flyout-header" }, [_vm._v("Group Info")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }