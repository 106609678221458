var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "p-4 overflow-y-auto shadow-inner" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "Package Sent" },
          model: {
            value: _vm.student.outProcessingPackagesent,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingPackagesent", $$v)
            },
            expression: "student.outProcessingPackagesent",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "Package Received" },
          model: {
            value: _vm.student.outProcessingPackagereceived,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingPackagereceived", $$v)
            },
            expression: "student.outProcessingPackagereceived",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "1288 Sent" },
          model: {
            value: _vm.student.outProcessingSent1288,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingSent1288", $$v)
            },
            expression: "student.outProcessingSent1288",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "1288 Received" },
          model: {
            value: _vm.student.outProcessingReceived1288,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingReceived1288", $$v)
            },
            expression: "student.outProcessingReceived1288",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "RSC" },
          model: {
            value: _vm.student.outProcessingRsc,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingRsc", $$v)
            },
            expression: "student.outProcessingRsc",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "Assignment Order" },
          model: {
            value: _vm.student.outProcessingAssignmentorder,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingAssignmentorder", $$v)
            },
            expression: "student.outProcessingAssignmentorder",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "GTCC" },
          model: {
            value: _vm.student.outProcessingGtcc,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingGtcc", $$v)
            },
            expression: "student.outProcessingGtcc",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "DISS" },
          model: {
            value: _vm.student.outProcessingDiss,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingDiss", $$v)
            },
            expression: "student.outProcessingDiss",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "Detach DTS Account" },
          model: {
            value: _vm.student.outProcessingDetachdtsaccount,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingDetachdtsaccount", $$v)
            },
            expression: "student.outProcessingDetachdtsaccount",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "Decoration Date" },
          model: {
            value: _vm.student.outProcessingDecorationdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingDecorationdate", $$v)
            },
            expression: "student.outProcessingDecorationdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "AAN" },
          model: {
            value: _vm.student.outProcessingAan,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingAan", $$v)
            },
            expression: "student.outProcessingAan",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "1288 MILPDS Action" },
          model: {
            value: _vm.student.outProcessingMilpdsaction1288,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingMilpdsaction1288", $$v)
            },
            expression: "student.outProcessingMilpdsaction1288",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { label: "DD 214" },
          model: {
            value: _vm.student.outProcessingDd214submitteddate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "outProcessingDd214submitteddate", $$v)
            },
            expression: "student.outProcessingDd214submitteddate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "font-semibold" }, [_vm._v("Personnel")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }