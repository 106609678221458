import { Store } from 'vuex';
import { FlyoutMounter } from '@/components/Flyout/FlyoutMounter';
import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchInProDto } from '@/models/Dtos/searchInProDto';
import { InPro } from '@/models/Entities/MaterializedViews/InPro';
import { RootState } from '@/store/types';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { inProTableConfigBuilder } from './InProConfigBuilder';
import { InProDataProvider } from './InProDataProvider';
import { InProTableData } from './InProTableData';

export const inProMiniTableConfig: IMiniTableConfiguration<
  InProTableData,
  InPro,
  never,
  SearchInProDto
> = {
  component: {
    component: MiniTable,
    title: 'Pre-InPro Pgill/Curry/Lopez/Trachy',
    key: 'Pre InPro'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<InProTableData, InPro, never, SearchInProDto>(
      store,
      new InProDataProvider(store),
      { relations: ['candidate'] },
      { defaultSortProperty: 'inProStartDate' },
      inProTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  },
  flyOutOptions: {
    cyTag: 'tag',
    openFlyoutHandler: (selectedData: InProTableData[]) => {
      const students = selectedData.map((data) => data.candidate);
      return FlyoutMounter.instance.refs.inprocessingFlyout.open(students);
    }
  }
};
