import { FlyoutMounter } from '@/components/Flyout/FlyoutMounter';
import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { ordersEndTableConfigBuilder } from './OrdersEndConfigBuilder';
import { OrdersEndDataProvider } from './OrdersEndDataProvider';
import { OrdersEndTableData } from './OrdersEndTableData';

export const ordersEndMiniTableConfig: IMiniTableConfiguration<
  OrdersEndTableData,
  OrdersEnd,
  never,
  SearchOrdersEndDto
> = {
  component: {
    component: MiniTable,
    title: 'Orders End Munn/Pgill',
    key: 'Orders End'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<
      OrdersEndTableData,
      OrdersEnd,
      never,
      SearchOrdersEndDto
    >(
      store,
      new OrdersEndDataProvider(store),
      {
        relations: ['candidate'],
        hasGradDateWithinLimit: true
      },
      { defaultSortProperty: 'orderEndDate' },
      ordersEndTableConfigBuilder,
      { filters: [], quickFilters: [] }
    );
  },
  flyOutOptions: {
    cyTag: 'tag',
    openFlyoutHandler: (selectedData: OrdersEndTableData[]) => {
      const students = selectedData.map((data) => data.student);
      return FlyoutMounter.instance.refs.ordersFlyout.open(students);
    }
  }
};
