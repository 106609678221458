var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-container" }, [
      _c("p", [_vm._v("Sir/Ma'am,")]),
      _c("p", [
        _vm._v(
          "What is the current balance on your Government Travel Card (GTC)?"
        ),
      ]),
      _c("ol", [
        _c("li", [
          _vm._v(
            " Please use the attached instructions to complete required GTC training (A-4) "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Review and sign attached statement of understanding (SOU) (A-5) Return training certificate and SOU to the 340th workflow inbox ("
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                rel: "noopener",
                href: "mailto:340FTG.UFT.Workflow@us.af.mil",
              },
            },
            [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
          ),
          _vm._v(") "),
        ]),
      ]),
      _c("p", [
        _vm._v("Let us know if you have questions about the GTC program."),
      ]),
      _c("p", [_vm._v("Thanks!")]),
      _c("p", [
        _vm._v(" V/R, "),
        _c("br"),
        _vm._v(" JANETTE M. LOPEZ, SSgt, USAF "),
        _c("br"),
        _vm._v(" 340th Undergraduate Flying Training, NCOIC "),
        _c("br"),
        _vm._v(" DSN: 487-6107 "),
        _c("br"),
        _vm._v(" Comm: "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "tel:210-652-6107",
            },
          },
          [_vm._v("210-652-6107")]
        ),
        _c("br"),
      ]),
      _c("p", [_vm._v("340th UFT Sharepoint (website):")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://randolph.eis.aetc.af.mil/340FTG/UFT/SitePages/Home.aspx",
            },
          },
          [_vm._v("340th UFT Sharepoint")]
        ),
      ]),
      _c("p", [_vm._v("Youtube: 340 FTG Public Affairs:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/channel/UCByIkPqNHL7e3oHjtWEYg4Q",
            },
          },
          [_vm._v("340 FTG Public Affairs")]
        ),
      ]),
      _c("p", [_vm._v("340 FTG WorkFlow Mailbox:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }