var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col w-full h-full max-w-xs" }, [
    _c("div", { staticClass: "h-full" }, [
      _vm.showIRR
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _vm.student.applicantType
                ? _c("radio", {
                    attrs: {
                      label: "IRR (Individual Ready Reserve)",
                      radioItems: _vm.yesNoOptions,
                    },
                    model: {
                      value: _vm.student.priorServiceInactivereadyreservestatus,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.student,
                          "priorServiceInactivereadyreservestatus",
                          $$v
                        )
                      },
                      expression:
                        "student.priorServiceInactivereadyreservestatus",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isRotc
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector-component", {
                attrs: {
                  "data-cy": "rotcGraduation",
                  label: "Graduation Date",
                  errorText: "Please enter a date",
                },
                model: {
                  value: _vm.student.academicInformationExpectedrotcgraduation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "academicInformationExpectedrotcgraduation",
                      $$v
                    )
                  },
                  expression:
                    "student.academicInformationExpectedrotcgraduation",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isRotc
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("radio", {
                attrs: {
                  "data-cy": "Stipend",
                  label: "Stipend",
                  radioItems: _vm.yesNoOptions,
                },
                model: {
                  value: _vm.student.militaryStatusStipend,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "militaryStatusStipend", $$v)
                  },
                  expression: "student.militaryStatusStipend",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isTraditionalReservist
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("text-input", {
                attrs: {
                  "data-cy": "dutyStationTextInput",
                  label: "Duty Station",
                  errorText: "Please enter the duty station",
                },
                model: {
                  value: _vm.student.priorServiceDutystation,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "priorServiceDutystation", $$v)
                  },
                  expression: "student.priorServiceDutystation",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isActiveGuardReserve
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("radio", {
                attrs: {
                  label: "Curtailment Started",
                  radioItems: _vm.yesNoOptions,
                },
                model: {
                  value: _vm.student.militaryStatusIscurtailmentstarted,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "militaryStatusIscurtailmentstarted",
                      $$v
                    )
                  },
                  expression: "student.militaryStatusIscurtailmentstarted",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isActiveGuardReserve
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector-component", {
                attrs: {
                  label: "Last Day of AGR Order",
                  errorText: "Please enter a date",
                },
                model: {
                  value: _vm.student.militaryStatusLastdayofagrorder,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "militaryStatusLastdayofagrorder",
                      $$v
                    )
                  },
                  expression: "student.militaryStatusLastdayofagrorder",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isActiveDuty
        ? _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c("radio", {
                attrs: { radioItems: _vm.activeDutyRadioOptions },
                model: {
                  value: _vm.student.priorServicePalace,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "priorServicePalace", $$v)
                  },
                  expression: "student.priorServicePalace",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isActiveDuty
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector-component", {
                staticClass: "mt-2",
                attrs: {
                  "data-cy": "EstimatedTimeOfSeparation",
                  label: "DOS (Date of Separation)",
                  errorText: "Please enter a date",
                },
                model: {
                  value: _vm.student.militaryStatusEstimatedtimeofseparation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "militaryStatusEstimatedtimeofseparation",
                      $$v
                    )
                  },
                  expression: "student.militaryStatusEstimatedtimeofseparation",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isAirNationalGuard
        ? _c(
            "div",
            [
              _c("radio", {
                staticClass: "mt-4",
                attrs: {
                  label: "Conditional Release Approved",
                  radioItems: _vm.yesNoOptions,
                },
                model: {
                  value: _vm.student.priorServiceIsconditionalreleaseapproved,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.student,
                      "priorServiceIsconditionalreleaseapproved",
                      $$v
                    )
                  },
                  expression:
                    "student.priorServiceIsconditionalreleaseapproved",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isSisterService
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("dropdown-component", {
                attrs: {
                  label: "Sister Service",
                  values: _vm.sisterServiceOptions,
                },
                model: {
                  value: _vm.student.priorServiceSisterservice,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "priorServiceSisterservice", $$v)
                  },
                  expression: "student.priorServiceSisterservice",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }