
import { Component, Vue, Mixins } from 'vue-property-decorator';
import StudentSelectionFlyoutComponent from '@/components/Flyout/StudentSelectionFlyoutComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import StudentVitalsComponent from '../../StudentDashboard/Gains/StudentVitalsComponent.vue';
import StudentServicesComponent from '../../StudentDashboard/OutProcessing/StudentServicesComponent.vue';
import PersonnelComponent from '../../StudentDashboard/OutProcessing/PersonnelComponent.vue';
import UpdateStudentMixin from '@/mixins/updateStudentMixin';

@Component<OrdersFlyoutComponent>({
  components: {
    StudentSelectionFlyoutComponent,
    StudentVitalsComponent,
    StudentServicesComponent,
    PersonnelComponent
  }
})
// TODO: check methods are working after students are passed and can render
export default class OrdersFlyoutComponent extends Mixins(
  UpdateStudentMixin,
  Vue
) {
  students!: Student[];
  show = false;
  activeStudentIndex = 0;

  resolve: () => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  public open(options: Student[]): Promise<void> {
    this.show = true;
    this.students = options ?? [];
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  close(): void {
    this.show = false;
    this.reject();
  }

  handleSelectStudent(index: number): void {
    this.activeStudentIndex = index;
  }

  get activeStudent(): Student {
    return this.students[this.activeStudentIndex];
  }
}
