
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/Modals/ModalComponent.vue';
import ModalMixin from '@/mixins/ModalMixin';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '../IconComponent.vue';

@Component<ItemSelectionModal>({
  components: {
    ModalComponent,
    ButtonComponent,
    IconComponent
  }
})
export default class ItemSelectionModal extends Mixins(Vue, ModalMixin) {
  @Prop({ default: () => 'Confirm' })
  confirmButtonText!: string;

  @Prop({ default: () => 'Cancel' })
  closeButtonText!: string;

  valueIndex!: string | number | symbol;
  textIndex!: string | number | symbol;

  model!: any[];

  public open<T>(data: T[], textIndex: keyof T, valueIndex: keyof T) {
    this.model = data.slice();
    this.textIndex = textIndex;
    this.valueIndex = valueIndex;
    this.show = true;
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }
}
