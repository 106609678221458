
import { Component, VModel, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { DropdownOptions, yesNoOptions } from '@/models/Forms/FormOption';
import Checkbox from '@/components/Forms/Elements/CheckboxComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import EmailModalComponent from '@/components/Modals/Email/EmailModalComponent.vue';
import TeamLeadEmailComponent from '@/components/Email/TeamLeadEmailComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<StudentInfoComponent>({
  components: {
    ButtonComponent,
    EmailModalComponent,
    DropdownComponent,
    IconComponent,
    Checkbox,
    TeamLeadEmailComponent,
    TextInput,
    TodaysDateButtonComponent
  }
})
export default class StudentInfoComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;

  dropdownItems: DropdownOptions[] = yesNoOptions;

  $refs!: {
    emailModal: EmailModalComponent;
  };

  handleEmailLeadClick(): void {
    this.$refs.emailModal.open();
  }
}
