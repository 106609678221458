var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "modal",
        {
          attrs: { size: _vm.size },
          on: {
            close: function ($event) {
              _vm.show = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "p-4 px-8",
              attrs: { "data-cy": "cautionModalComponent" },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-center" },
                [
                  _vm.icon.length
                    ? _c("font-awesome-icon", {
                        staticClass: "overflow-visible h-11",
                        style: { color: _vm.iconColor },
                        attrs: { icon: _vm.icon },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.title
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-center mb-4 text-3xl capitalize",
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _vm._e(),
              _vm.message
                ? _c("div", { staticClass: "flex justify-center text-xl" }, [
                    _vm._v(" " + _vm._s(_vm.message) + " "),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex my-4" },
                [
                  _c(
                    "button-component",
                    {
                      staticClass: "ml-auto",
                      attrs: { "data-cy": "cancelButton", border: false },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.cancelText))]
                  ),
                  _c(
                    "button-component",
                    {
                      staticClass: "ml-4",
                      attrs: { "data-cy": "continueButton" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v(_vm._s(_vm.continueText))]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }