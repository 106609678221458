import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { SearchTransitionDto } from '@/models/Dtos/searchTransitionDto';
import { Transition } from '@/models/Entities/MaterializedViews/Transition';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TableConfigBuilder } from '../../../Table/models/TableConfigBuilder';
import { TransitionTableData } from './TransitionTableData';

export const transitionTableConfigBuilder: TableConfigBuilder<
  TransitionTableData,
  Transition,
  never,
  SearchTransitionDto
> = (): ITableConfiguration<TransitionTableData, Transition, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: TransitionTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.student.id }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Previous Course',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: TransitionTableData) => {
            return {
              date: rowData.previousCourseEndDate
            };
          }
        },
        sortKey: 'previousCourseEndDate'
      },
      {
        columnTitle: 'Next Course',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: TransitionTableData) => {
            return {
              date: rowData.nextCourseStartDate
            };
          }
        },
        sortKey: 'nextCourseStartDate'
      },
      {
        columnTitle: 'Days',
        render: 'transitionDays',
        sortKey: 'daysBetweenLastCourseAndNextCourse'
      }
    ]
  };
};
