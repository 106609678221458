import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchCensusDto } from '@/models/Dtos/searchCensusDto';
import { Census } from '@/models/Entities/MaterializedViews/Census';
import { ITableConfiguration } from '@/models/Table/Table';
import { CensusTableData } from './CensusTableData';

export const censusTableConfigBuilder: TableConfigBuilder<
  CensusTableData,
  Census,
  never,
  SearchCensusDto
> = (): ITableConfiguration<CensusTableData, Census, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Phase',
        render: 'phase'
      },
      {
        columnTitle: 'Total',
        render: 'total',
        sortKey: 'total'
      }
    ]
  };
};
