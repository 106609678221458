var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-container" }, [
      _c("p", [
        _vm._v(
          ' All, If you are in the "TO" box, you have been assigned as the team lead for in-processing, all others members have been cc\'d for communication purposes. Please review "A-11 Team Lead Responsibilities". Also, please download class roster (DoD Safe link) and contact your team members. '
        ),
      ]),
      _c("p", [_vm._v("Please ensure the following for each team member:")]),
      _c("p", [
        _vm._v(
          " Airline reservations were made using TRAVCO (see attachment A-1, pg. 8 for funding details) "
        ),
      ]),
      _c("p", [_vm._v("A military ID was issued prior to inprocessing")]),
      _c("p", [
        _vm._v(' "FM package" was submitted to the 340th workflow email ('),
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil)")]
        ),
        _vm._v(") "),
      ]),
      _c("p", [_vm._v("Bring personal laptops")]),
      _c("p", [_vm._v("Report time is 0830")]),
      _c("p", [_vm._v("Download a QR reader to cell phones")]),
      _c("p", [
        _vm._v(" Call "),
        _c(
          "a",
          {
            attrs: { target: "_blank", rel: "noopener", href: "210-275-4938" },
          },
          [_vm._v("210-275-4938")]
        ),
        _vm._v(
          " for emergencies (non-duty hours) TAINELL M. PETTENGILL, MSgt, USAF 340th Undergraduate Flying Training, NCOIC DSN: 487-6107 Comm: "
        ),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "tel:210-652-6107",
            },
          },
          [_vm._v("210-652-6107")]
        ),
      ]),
      _c("p", [_vm._v("340th UFT Sharepoint (website):")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://randolph.eis.aetc.af.mil/340FTG/UFT/SitePages/Home.aspx",
            },
          },
          [_vm._v("340th UFT Sharepoint")]
        ),
      ]),
      _c("p", [_vm._v("Youtube: 340 FTG Public Affairs:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/channel/UCByIkPqNHL7e3oHjtWEYg4Q",
            },
          },
          [_vm._v("340 FTG Public Affairs")]
        ),
      ]),
      _c("p", [_vm._v("340 FTG WorkFlow Mailbox:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }