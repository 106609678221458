
import { Component, VModel, Vue } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<AfterOtsComponent>({
  components: {
    TextInput,
    TodaysDateButtonComponent
  }
})
export default class AfterOtsComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;
}
