import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { OutPro } from '@/models/Entities/MaterializedViews/OutPro';
import { ExitReason } from '@/models/Entities/Student/Constants';
import { DisplayNameUtil } from '@/util/displayName';

export class OutProTableData {
  reason!: ExitReason | null;
  edcsa!: Date | null;
  sent1288!: Date | null;
  received1288!: Date | null;
  candidateId!: number;
  candidate!: BaseCandidate;
  name!: string;

  constructor(outPro: OutPro) {
    this.reason = outPro.reason;
    this.edcsa = outPro.edcsa;
    this.sent1288 = outPro.sent1288;
    this.received1288 = outPro.received1288;
    if (outPro.firstName && outPro.lastName) {
      this.name = DisplayNameUtil.flattenName(
        outPro.firstName.toUpperCase(),
        outPro.lastName.toUpperCase()
      );
    }
    this.candidateId = outPro.candidateId;
    this.candidate = outPro.candidate;
  }
}
