var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col max-h-screen" },
    [
      _c("div", { staticClass: "flex-shrink-0 border-b p-7" }),
      _c(
        "div",
        { staticClass: "p-4 overflow-y-auto shadow-inner" },
        [
          _c("label", [_vm._v("Send GTCC E-mail")]),
          _c(
            "div",
            [
              _c(
                "button-component",
                {
                  attrs: {
                    "data-cy": "handleExistingAccountEmailClick",
                    textOrBorderColor: "white",
                    bg: "val-button-blue",
                  },
                  on: { click: _vm.handleExistingAccountEmailClick },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("icon-component", {
                        staticClass: "flex-grow-0 flex-shrink-0 mr-2",
                        attrs: {
                          fill: "white",
                          stroke: "white",
                          width: 16,
                          height: 10,
                          name: "email",
                        },
                      }),
                      _c("span", [_vm._v("Existing Account")]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c(
                "button-component",
                {
                  attrs: {
                    "data-cy": "handleNewAccountEmailClick",
                    textOrBorderColor: "white",
                    bg: "val-button-blue",
                  },
                  on: { click: _vm.handleNewAccountEmailClick },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("icon-component", {
                        staticClass: "flex-grow-0 flex-shrink-0 mr-2",
                        attrs: {
                          fill: "white",
                          stroke: "white",
                          width: 16,
                          height: 10,
                          name: "email",
                        },
                      }),
                      _c("span", [_vm._v("New Account")]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "gtccEmail",
              label: "GTCC e-mail",
              alignment: "top",
            },
            model: {
              value: _vm.student.inProcessingGtccemail,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtccemail", $$v)
              },
              expression: "student.inProcessingGtccemail",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "gtcSuperVisorDate", label: "GTC Supervisor" },
            model: {
              value: _vm.student.inProcessingGtcsupervisordate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtcsupervisordate", $$v)
              },
              expression: "student.inProcessingGtcsupervisordate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "gtcApcDate", label: "GTC APC" },
            model: {
              value: _vm.student.inProcessingGtcapcdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtcapcdate", $$v)
              },
              expression: "student.inProcessingGtcapcdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "gtcActivationDate", label: "GTC Activation" },
            model: {
              value: _vm.student.inProcessingGtcactivationdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtcactivationdate", $$v)
              },
              expression: "student.inProcessingGtcactivationdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "gtcCsouDate", label: "GTC SOU" },
            model: {
              value: _vm.student.inProcessingGtccsoudate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtccsoudate", $$v)
              },
              expression: "student.inProcessingGtccsoudate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "gtcCBTDate", label: "GTC CBT" },
            model: {
              value: _vm.student.inProcessingGtccbtdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingGtccbtdate", $$v)
              },
              expression: "student.inProcessingGtccbtdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "orderDate", label: "Order" },
            model: {
              value: _vm.student.inProcessingOrderdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingOrderdate", $$v)
              },
              expression: "student.inProcessingOrderdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: { "data-cy": "orderSentDate", label: "Order Sent" },
            model: {
              value: _vm.student.inProcessingOrdersentdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingOrdersentdate", $$v)
              },
              expression: "student.inProcessingOrdersentdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "outlookDistroDate",
              label: "Outlook Distro",
              alignment: "bottom",
            },
            model: {
              value: _vm.student.inProcessingOutlookdistrodate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingOutlookdistrodate", $$v)
              },
              expression: "student.inProcessingOutlookdistrodate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "dtsAccountDate",
              label: "DTS Account",
              alignment: "bottom",
            },
            model: {
              value: _vm.student.inProcessingDtsaccountdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingDtsaccountdate", $$v)
              },
              expression: "student.inProcessingDtsaccountdate",
            },
          }),
        ],
        1
      ),
      _c(
        "email-modal-component",
        {
          ref: "newGTCCEmail",
          attrs: {
            to: _vm.student.contactInformationEmail,
            subject: "GTCC Application - 3 Steps for New Applicants",
          },
        },
        [_c("new-gtcc-email")],
        1
      ),
      _c(
        "email-modal-component",
        {
          ref: "existingGTCCEmail",
          attrs: {
            to: _vm.student.contactInformationEmail,
            subject: "GTCC Information Request for Existing Account",
          },
        },
        [_c("existing-gtcc-email")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }