import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { ITableConfiguration } from '@/models/Table/Table';
import { InPro } from '@/models/Entities/MaterializedViews/InPro';
import { SearchInProDto } from '@/models/Dtos/searchInProDto';
import Routes from '@/router/Routes';
import { InProTableData } from './InProTableData';

export const inProTableConfigBuilder: TableConfigBuilder<
  InProTableData,
  InPro,
  never,
  SearchInProDto
> = (): ITableConfiguration<InProTableData, InPro, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: InProTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Rank',
        render: 'ranking',
        sortKey: 'ranking'
      },
      {
        columnTitle: 'Welcome',
        render: 'welcome',
        sortKey: 'welcome'
      },
      {
        columnTitle: 'GTC',
        render: 'gtc',
        sortKey: 'gtc'
      },
      {
        columnTitle: 'Lead',
        render: 'lead'
      },
      {
        columnTitle: 'Gained',
        render: 'gained'
      },
      {
        columnTitle: 'In-Pro',
        render: 'inProStartDate',
        sortKey: 'inProStartDate'
      }
    ]
  };
};
