
import { Component, VModel, Vue } from 'vue-property-decorator';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Checkbox from '@/components/Forms/Elements/CheckboxComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import {
  AirForcePrograms,
  SisterServices
} from '@/models/Entities/Student/Constants';
import { Student } from '@/models/Entities/Student/Student';
import { ApplicantTypes } from '@/models/Entities/Personas/Constants';
import { yesNoOptions } from '@/models/Forms/FormOption';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';

@Component<DutyStatusColumnComponent>({
  components: {
    Radio,
    DropdownComponent,
    TextInput,
    Checkbox,
    DateSelectorComponent
  }
})
export default class DutyStatusColumnComponent extends Vue {
  @VModel({ required: true })
  student!: Student;

  yesNoOptions = yesNoOptions;
  activeDutyRadioOptions = getInputOptions(AirForcePrograms);
  sisterServiceOptions = getInputOptions(SisterServices);

  get isActiveDuty(): boolean {
    return this.student.applicantType === ApplicantTypes.ACTIVE_DUTY;
  }
  get isRotc(): boolean {
    return this.student.applicantType === ApplicantTypes.ROTC;
  }
  get isTraditionalReservist(): boolean {
    return this.student.applicantType === ApplicantTypes.TRADITIONAL_RESERVIST;
  }
  get isActiveGuardReserve(): boolean {
    return this.student.applicantType === ApplicantTypes.ACTIVE_GUARD_RESERVE;
  }
  get isAirNationalGuard(): boolean {
    return this.student.applicantType === ApplicantTypes.AIR_NATIONAL_GUARD;
  }
  get isSisterService(): boolean {
    return this.student.applicantType === ApplicantTypes.SISTER_SERVICE;
  }
  get isIMA(): boolean {
    return (
      this.student.applicantType ===
      ApplicantTypes.INDIVIDUAL_MOBILIZATION_AUGMENTEE
    );
  }
  get showIRR(): boolean {
    return (
      this.student.applicantType &&
      this.student.applicantType !==
        ApplicantTypes.INDIVIDUAL_MOBILIZATION_AUGMENTEE
    );
  }
}
