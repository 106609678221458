
import ActionMenuComponent from '@/components/ActionMenus/ActionMenuComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FlyoutMounterComponent from '@/components/Flyout/FlyoutMounterComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import CautionModalComponent from '@/components/Modals/CautionModalComponent.vue';
import ItemSelectionModal from '@/components/Modals/ItemSelectionModal.vue';
import SelectFileToUploadComponent from '@/components/SelectFileToUploadComponent.vue';
import { EIconColors } from '@/enums';
import { Actions } from '@/enums/EActionTypes';
import LocalStorageMixin from '@/mixins/LocalStorageMixin';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { ActionMenuItem } from '@/models/ActionMenuItem';
import Routes from '@/router/Routes';
import { deepCopy } from '@/util/deepCopy';
import {
  Component,
  Mixins,
  ProvideReactive,
  Vue
} from 'vue-property-decorator';
import {
  studentDashboardMiniTables,
  TableDisplayConfiguration
} from './StudentDashboard/MiniTableConfigs';

@Component<StudentDashboardPage>({
  components: {
    ActionMenuComponent,
    ButtonComponent,
    IconComponent,
    SelectFileToUploadComponent,
    ItemSelectionModal,
    CautionModalComponent,
    FlyoutMounterComponent
  }
})
export default class StudentDashboardPage extends Mixins(
  SnackbarMixin,
  LocalStorageMixin,
  Vue
) {
  @ProvideReactive('expanded')
  get tableViewExpanded(): boolean {
    return !!this.activeTableName;
  }

  iconColors = EIconColors;

  $refs!: {
    fileUpload: SelectFileToUploadComponent;
    miniTableConfigurationModal: ItemSelectionModal;
    cautionModalComponent: CautionModalComponent;
  };

  private tableConfiguration: TableDisplayConfiguration[] = [];

  importDataAction!: 'importTlnData' | 'importDcrData';
  dashboardConfigurationKey = 'studentDashboardPageMiniTableOrderConfiguration';
  actions: ActionMenuItem[] = [];
  administrator: ActionMenuItem[] = [];
  miniTables = studentDashboardMiniTables.map((value) => {
    const { tableSearchServiceBuilder, ...config } = { ...value };
    return {
      ...config,
      tableSearchService: tableSearchServiceBuilder(this.$store)
    };
  });

  created(): void {
    const foundConfiguration = this.localStorageGetItem(
      this.dashboardConfigurationKey
    );
    if (foundConfiguration) {
      this.tableConfiguration = foundConfiguration;
      return;
    }
    this.tableConfiguration = this.miniTables.map((table) => {
      return {
        title: table.component.title,
        key: table.component.key,
        showTable: true
      };
    });
  }

  mounted(): void {
    this.administrator = [
      new ActionMenuItem({
        displayText: 'Dashboard Setup',
        value: Actions.CONFIGURE_DASHBOARD,
        showCount: false,
        action: async () => {
          const newConfiguration =
            await this.$refs.miniTableConfigurationModal.open(
              deepCopy(this.tableConfiguration),
              'key',
              'showTable'
            );
          this.localStorageSetItem(
            this.dashboardConfigurationKey,
            newConfiguration
          );
          this.tableConfiguration = this.localStorageGetItem(
            this.dashboardConfigurationKey
          );
        }
      })
    ];
    this.actions = [
      new ActionMenuItem({
        displayText: 'OTS Classes',
        value: Actions.OTS_CLASSES,
        showCount: false,
        action: () => {
          this.$router.push({ name: Routes.OTS_CLASSES });
        }
      }),
      new ActionMenuItem({
        displayText: 'Inpro Groups',
        value: Actions.INPRO_CLASSES,
        showCount: false,
        action: () => {
          this.$router.push({ name: Routes.INPRO_CLASSES });
        }
      }),
      new ActionMenuItem({
        displayText: 'Sponsoring Units',
        value: Actions.SPONSORING_UNITS,
        showCount: false,
        action: () => {
          this.$router.push({ name: Routes.SPONSORING_UNITS });
        }
      }),
      new ActionMenuItem({
        displayText: 'Import DCR',
        value: Actions.IMPORT_DCR,
        showCount: false,
        action: () => {
          this.importDataAction = 'importDcrData';
          this.$refs.fileUpload.chooseFile();
        }
      }),
      new ActionMenuItem({
        displayText: 'Import TLN',
        value: Actions.IMPORT_TLN,
        showCount: false,
        action: () => {
          this.importDataAction = 'importTlnData';
          this.$refs.fileUpload.chooseFile();
        }
      })
    ];
  }

  get filteredMiniTables() {
    if (this.activeTableName) {
      return this.miniTables.filter((miniTableComponent) => {
        return (
          !this.activeTableName ||
          this.activeTableName === miniTableComponent.component.title
        );
      });
    }
    return this.miniTables.filter((miniTableComponent) => {
      const foundConfiguration = this.tableConfiguration.find(
        (config) => miniTableComponent.component.key === config.key
      );
      return foundConfiguration ? foundConfiguration.showTable : true;
    });
  }

  handleSelectAction(action: ActionMenuItem): void {
    action.action();
  }

  async importData(files: File[]): Promise<void> {
    try {
      this.showSnackbar({
        message: 'Import job has started',
        icon: 'pencil',
        timeout: 5000
      });
      await this.$store.dispatch(
        `studentModule/${this.importDataAction}`,
        files[0]
      );
      this.showSnackbar({
        message: 'Import job has finished',
        icon: 'checkGreen',
        timeout: 5000
      });
    } catch (err) {
      this.showSnackbar({
        message: 'There was an error importing the data',
        icon: 'caution'
      });
    }
  }

  onHeadingClick(): void {
    if (this.$route.query.table) {
      this.$router.push({ query: { table: undefined } });
    }
  }

  onTitleClick(table: string): void {
    if (table === this.$route.query.table) {
      this.$router.push({ query: { table: undefined } });
    } else {
      this.$router.push({ query: { table } });
    }
  }

  get activeTableName(): string | (string | null)[] {
    return this.$route.query.table;
  }
}
