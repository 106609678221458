
import { Component, VModel, Vue } from 'vue-property-decorator';
import Checkbox from '@/components/Forms/Elements/CheckboxComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import TodaysDateButtonComponent from '@/components/Forms/Elements/TodaysDateButtonComponent.vue';
import EmailModalComponent from '@/components/Modals/Email/EmailModalComponent.vue';
import ExistingGtccEmail from '@/components/Email/ExistingGTCCEmailComponent.vue';
import NewGtccEmail from '@/components/Email/NewGTCCEmailComponent.vue';
import { Student } from '@/models/Entities/Student/Student';

@Component<GtcColumnComponent>({
  components: {
    ButtonComponent,
    Checkbox,
    EmailModalComponent,
    ExistingGtccEmail,
    IconComponent,
    NewGtccEmail,
    TodaysDateButtonComponent
  }
})
export default class GtcColumnComponent extends Vue {
  @VModel({
    required: true
  })
  student!: Student;

  $refs!: {
    existingGTCCEmail: EmailModalComponent;
    newGTCCEmail: EmailModalComponent;
  };

  handleExistingAccountEmailClick(): void {
    this.$refs.existingGTCCEmail.open();
  }

  handleNewAccountEmailClick(): void {
    this.$refs.newGTCCEmail.open();
  }
}
