import { plainToClass, Transform, Type } from 'class-transformer';
import { removeTimeZoneOffset } from '../../../util/class-transformer/removeTimeZoneOffset';
import { BaseCandidate } from '../BaseCandidate';

export class OTS {
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;
  @Type(/* istanbul ignore next */ () => String)
  classNumber!: string;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  graduationDate!: Date;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  classStartDate!: Date;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  wings!: Date;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  onSite!: Date;
  firstName!: string;
  lastName!: string;

  constructor(partial?: Partial<OTS>) {
    if (partial) {
      Object.assign(this, plainToClass(OTS, partial));
    }
  }
}
