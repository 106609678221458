import MiniTable from '@/components/StudentDashboard/MiniTables/MiniTable.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { SearchOTSDto } from '@/models/Dtos/searchOTSDto';
import { OTS } from '@/models/Entities/MaterializedViews/OTS';
import { IMiniTableConfiguration } from '../MiniTableConfiguration';
import { OTSTableConfigBuilder } from './OTSConfigBuilder';
import { OTSDataProvider } from './OTSDataProvider';
import { OTSTableData } from './OTSTableData';

export const OTSMiniTableConfig: IMiniTableConfiguration<
  OTSTableData,
  OTS,
  never,
  SearchOTSDto
> = {
  component: {
    component: MiniTable,
    title: 'OTS (OBJ #4) Trachy/Lopez',
    key: 'OTS'
  },
  emptyTableMessage: 'No Applicants',
  tableSearchServiceBuilder: (store: Store<RootState>) => {
    return new TableSearchService<OTSTableData, OTS, never, SearchOTSDto>(
      store,
      new OTSDataProvider(store),
      {
        relations: ['candidate']
      },
      { defaultSortProperty: 'classStartDate' },
      OTSTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
  }
};
