var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen mt-5" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-6" },
      [
        _c("div", { staticClass: "text-xs text-dark" }, [
          _vm._v(" " + _vm._s(_vm.student.applicantType || "") + " "),
        ]),
        _c("duty-status-column-component", {
          staticClass: "flex-grow flex-shrink-0",
          model: {
            value: _vm.student,
            callback: function ($$v) {
              _vm.student = $$v
            },
            expression: "student",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "flyout-header" }, [_vm._v("Duty Status")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }