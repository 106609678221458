
import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator';
import GainsFlyoutComponent from './340th/GainsFlyoutComponent.vue';
import InProcessingFlyoutComponent from './340th/InProcessingFlyoutComponent.vue';
import OrdersFlyoutComponent from './340th/OrdersFlyoutComponent.vue';
import OtsFlyoutComponent from './340th/OtsFlyoutComponent.vue';
import StartPayFlyoutComponent from './340th/StartPayFlyoutComponent.vue';
import { FlyoutMounter } from './FlyoutMounter';

@Component<FlyoutMounterComponent>({
  components: {
    GainsFlyoutComponent,
    InProcessingFlyoutComponent,
    OtsFlyoutComponent,
    OrdersFlyoutComponent,
    StartPayFlyoutComponent
  }
})
export default class FlyoutMounterComponent extends Vue {
  $refs!: {
    gainsFlyout: GainsFlyoutComponent;
    inprocessingFlyout: InProcessingFlyoutComponent;
    otsFlyout: OtsFlyoutComponent;
    ordersFlyout: OrdersFlyoutComponent;
    startpayFlyout: StartPayFlyoutComponent;
  };

  created(): void {
    FlyoutMounter.instance.init(this.$refs);
  }
}
