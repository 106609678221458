
import { Component, Vue, Mixins } from 'vue-property-decorator';
import StudentSelectionFlyoutComponent from '@/components/Flyout/StudentSelectionFlyoutComponent.vue';
import StudentVitalsComponent from '@/components/StudentDashboard/Gains/StudentVitalsComponent.vue';
import AfterOtsComponent from '@/components/StudentDashboard/OfficerTrainingSchoolFlyout/AfterOtsComponent.vue';
import BeforeOtsComponent from '@/components/StudentDashboard/OfficerTrainingSchoolFlyout/BeforeOtsComponent.vue';
import DuringOtsComponent from '@/components/StudentDashboard/OfficerTrainingSchoolFlyout/DuringOtsComponent.vue';
import { Student } from '@/models/Entities/Student/Student';
import UpdateStudentMixin from '@/mixins/updateStudentMixin';

@Component<OtsFlyoutComponent>({
  components: {
    BeforeOtsComponent,
    DuringOtsComponent,
    AfterOtsComponent,
    StudentSelectionFlyoutComponent,
    StudentVitalsComponent
  }
})
// TODO: check methods are working after students are passed and can render
export default class OtsFlyoutComponent extends Mixins(
  UpdateStudentMixin,
  Vue
) {
  students!: Student[];

  activeStudentIndex = 0;

  show = false;

  resolve: () => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  public open(options: Student[]): Promise<void> {
    this.show = true;
    this.students = options ?? [];
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  close(): void {
    this.show = false;
    this.reject();
  }

  handleSelectStudent(index: number): void {
    this.activeStudentIndex = index;
  }

  get activeStudent(): Student {
    return this.students[this.activeStudentIndex];
  }
}
