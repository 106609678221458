import { Student } from '../../models/Entities/Student/Student';
import { Recruiter } from '@/models/Entities/Personas/Recruiter';
import { IFlyout } from './IFlyout';

export class FlyoutMounter {
  public static instance = new FlyoutMounter();
  public refs!: FlyoutRefs;

  public init(refs: FlyoutRefs): void {
    FlyoutMounter.instance.refs = refs;
  }
}

export interface GainsFlyoutOptions {
  students?: Student[];
  recruiters?: Recruiter[];
}

export interface FlyoutRefs {
  gainsFlyout: IFlyout<GainsFlyoutOptions, void>;
  inprocessingFlyout: IFlyout<Student[], void>;
  otsFlyout: IFlyout<Student[], void>;
  ordersFlyout: IFlyout<Student[], void>;
  startpayFlyout: IFlyout<Student[], void>;
}
