var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen mt-5" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-6 overflow-y-auto" },
      [
        _c("radio", {
          attrs: { radioItems: _vm.radioItems },
          model: {
            value: _vm.student.gainsPoctype,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsPoctype", $$v)
            },
            expression: "student.gainsPoctype",
          },
        }),
        _vm.student.gainsPoctype === _vm.PocType.FSS
          ? _c(
              "div",
              { key: "FSS", staticClass: "mt-4" },
              [
                _c("text-input", {
                  staticClass: "mb-4",
                  attrs: { label: "FSS POC" },
                  model: {
                    value: _vm.student.gainsIncomingfsspoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "gainsIncomingfsspoc", $$v)
                    },
                    expression: "student.gainsIncomingfsspoc",
                  },
                }),
                _c("text-input", {
                  staticClass: "mb-4",
                  attrs: { label: "FSS Email" },
                  model: {
                    value: _vm.student.gainsIncomingfssemail,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "gainsIncomingfssemail", $$v)
                    },
                    expression: "student.gainsIncomingfssemail",
                  },
                }),
                _c("phone-input", {
                  attrs: { label: "FSS Phone" },
                  model: {
                    value: _vm.student.gainsIncomingfssphone,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "gainsIncomingfssphone", $$v)
                    },
                    expression: "student.gainsIncomingfssphone",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.student.gainsPoctype === _vm.PocType.RECRUITER
          ? _c(
              "div",
              { key: "Recruiter", staticClass: "mt-4" },
              [
                _c("text-input", {
                  staticClass: "mb-4",
                  attrs: { label: "Recruiter POC", readonly: true },
                  model: {
                    value: _vm.student.recruiterPocInformation.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.recruiterPocInformation, "name", $$v)
                    },
                    expression: "student.recruiterPocInformation.name",
                  },
                }),
                _c("text-input", {
                  staticClass: "mb-4",
                  attrs: { label: "Recruiter Email", readonly: true },
                  model: {
                    value: _vm.student.recruiterPocInformation.email,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.student.recruiterPocInformation,
                        "email",
                        $$v
                      )
                    },
                    expression: "student.recruiterPocInformation.email",
                  },
                }),
                _c("phone-input", {
                  attrs: { label: "Recruiter Phone", readonly: true },
                  model: {
                    value: _vm.student.recruiterPocInformation.phone,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.student.recruiterPocInformation,
                        "phone",
                        $$v
                      )
                    },
                    expression: "student.recruiterPocInformation.phone",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "tracking-wide flyout-header" }, [
        _vm._v("FSS/Recruiter Info"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }