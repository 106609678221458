import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { TableDataProvider } from '../../../Table/models/TableDataProvider';
import { OrdersEndTableData } from './OrdersEndTableData';

export class OrdersEndDataProvider extends TableDataProvider<
  OrdersEndTableData,
  OrdersEnd,
  SearchOrdersEndDto
> {
  protected transformer = (t: OrdersEnd): OrdersEndTableData =>
    new OrdersEndTableData(t);

  protected queryHandler = async (
    searchDto: SearchOrdersEndDto
  ): Promise<SearchResponseDto<OrdersEnd>> => {
    const results: SearchResponseDto<OrdersEnd> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'ordersEnd', query: searchDto, model: OrdersEnd }
    );
    return results;
  };
}
