import { ELogActionType } from '@/enums/EActionTypes';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { Type, Transform, plainToClass } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';
import { Log } from '../Log';

export class ImmediateAction {
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;
  @Type(/* istanbul ignore next */ () => Log)
  log!: Log;
  firstName!: string | null;
  lastName!: string | null;
  topic!: ELogActionType | null;
  personOfContact!: number | null;
  @Type(/* istanbul ignore next */ () => Number)
  logId!: number | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  dateCreated!: Date;

  constructor(partial?: Partial<ImmediateAction>) {
    if (partial) {
      Object.assign(this, plainToClass(ImmediateAction, partial));
    }
  }
}
