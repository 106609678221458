var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col max-h-screen" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-6 pb-4 overflow-y-auto" },
      [
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "gainDate",
            label: "Gain date updated",
            alignment: "top",
          },
          model: {
            value: _vm.student.gainsGaindate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsGaindate", $$v)
            },
            expression: "student.gainsGaindate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "ddAformationDate",
            label: "DD form 4/AF form 133",
          },
          model: {
            value: _vm.student.gainsDdaformationdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsDdaformationdate", $$v)
            },
            expression: "student.gainsDdaformationdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "dutyTitleDate", label: "Duty title" },
          model: {
            value: _vm.student.gainsDutytitledate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsDutytitledate", $$v)
            },
            expression: "student.gainsDutytitledate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "aefBanDate", label: "AEF band" },
          model: {
            value: _vm.student.gainsAefbandate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsAefbandate", $$v)
            },
            expression: "student.gainsAefbandate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "afrSectionIdDate", label: "AFR section id" },
          model: {
            value: _vm.student.gainsAfrsectioniddate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsAfrsectioniddate", $$v)
            },
            expression: "student.gainsAfrsectioniddate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: { "data-cy": "surfDate", label: "SURF" },
          model: {
            value: _vm.student.gainsSurfdate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsSurfdate", $$v)
            },
            expression: "student.gainsSurfdate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "compCatConfirmedDate",
            label: "COMPCat confirmed",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.gainsCompcatconfirmeddate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsCompcatconfirmeddate", $$v)
            },
            expression: "student.gainsCompcatconfirmeddate",
          },
        }),
        _c("todays-date-button-component", {
          staticClass: "mb-2",
          attrs: {
            "data-cy": "postActionDate",
            label: "Post action",
            alignment: "bottom",
          },
          model: {
            value: _vm.student.gainsPostactiondate,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsPostactiondate", $$v)
            },
            expression: "student.gainsPostactiondate",
          },
        }),
        _c("div", { staticClass: "mt-8 mb-4 flyout-header" }, [
          _vm._v("Priority"),
        ]),
        _c("dropdown-component", {
          staticClass: "mb-6",
          attrs: { values: _vm.gainsPriorityOptions },
          model: {
            value: _vm.student.gainsGainspriority,
            callback: function ($$v) {
              _vm.$set(_vm.student, "gainsGainspriority", $$v)
            },
            expression: "student.gainsGainspriority",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 px-6 py-4" }, [
      _c("div", { staticClass: "flyout-header" }, [_vm._v("Post Gains")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }