import { Type, Transform, plainToClass } from 'class-transformer';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { BaseCandidate } from '../BaseCandidate';

export class StartPay {
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;
  firstName!: string | null;
  lastName!: string | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  startDate!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  modEndDate!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  emailDate!: Date | null;
  @Type(/* istanbul ignore next */ () => Number)
  mod!: number | null;
  @Type(/* istanbul ignore next */ () => Number)
  trackingNumber!: number | null;
  @Type(/* istanbul ignore next */ () => Number)
  transactionId!: number | null;

  constructor(partial?: Partial<StartPay>) {
    if (partial) {
      Object.assign(this, plainToClass(StartPay, partial));
    }
  }
}
