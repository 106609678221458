import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchSECDto } from '@/models/Dtos/searchSECDto';
import { SEC } from '@/models/Entities/MaterializedViews/SEC';
import { SECTableData } from './SECTableData';

export class SECDataProvider extends TableDataProvider<
  SECTableData,
  SEC,
  SearchSECDto
> {
  protected transformer = (t: SEC): SECTableData => new SECTableData(t);

  protected queryHandler = async (
    searchDto: SearchSECDto
  ): Promise<SearchResponseDto<SEC>> => {
    const results: SearchResponseDto<SEC> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'SEC', query: searchDto, model: SEC }
    );
    return results;
  };
}
