import { plainToClass, Type } from 'class-transformer';
import { PhaseType } from '../Student/Constants';

export class Census {
  phase!: PhaseType;
  @Type(/* istanbul ignore next */ () => Number)
  total!: number;

  constructor(partial?: Partial<Census>) {
    if (partial) {
      Object.assign(this, plainToClass(Census, partial));
    }
  }
}
