import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchOTSDto } from '@/models/Dtos/searchOTSDto';
import { OTS } from '@/models/Entities/MaterializedViews/OTS';
import { OTSTableData } from './OTSTableData';

export class OTSDataProvider extends TableDataProvider<
  OTSTableData,
  OTS,
  SearchOTSDto
> {
  protected transformer = (t: OTS): OTSTableData => new OTSTableData(t);

  protected queryHandler = async (
    searchDto: SearchOTSDto
  ): Promise<SearchResponseDto<OTS>> => {
    const results: SearchResponseDto<OTS> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'OTS', query: searchDto, model: OTS }
    );
    return results;
  };
}
