
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fontAwesomeConfig } from '../types';
import ModalMixin from '@/mixins/ModalMixin';
import { EIconColors } from '@/enums';

@Component<CautionModalComponent>({
  components: {
    ButtonComponent,
    Modal,
    FontAwesomeIcon
  }
})
export default class CautionModalComponent extends Mixins(Vue, ModalMixin) {
  @Prop({ default: 'sm' })
  size!: string;

  @Prop({ default: ['', ''] })
  icon!: fontAwesomeConfig;

  @Prop({ default: EIconColors.BLACK })
  iconColor!: EIconColors;

  @Prop({ default: 'Cancel' })
  cancelText!: string;

  @Prop({ default: 'Continue' })
  continueText!: string;

  public open(message = 'Are you sure?'): Promise<void> {
    this.message = message;
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
