var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-4 bg-white border rounded shadow" },
    [
      _c(
        "async-table-component",
        _vm._g(
          _vm._b(
            {
              attrs: {
                isSelectable: _vm.hasFlyout,
                isSortable: true,
                localStorageKey: "applicant-collection-table",
                emptyTableMessage: _vm.config.emptyTableMessage,
                compact: true,
                canEditPaginatedRows: _vm.expanded,
              },
            },
            "async-table-component",
            _vm.tableSearchService.tableProps,
            false
          ),
          _vm.tableSearchService.tableEventListeners
        ),
        [
          _c("template", { slot: "settings-left" }, [
            _c("div", { staticClass: "w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "font-semibold underline cursor-pointer whitespace-nowrap",
                  attrs: { "data-cy": "gainsMiniTableTitle" },
                  on: { click: _vm.emitTitleClick },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "settings-right" },
            [
              _vm.hasFlyout
                ? _c(
                    "button-component",
                    {
                      attrs: {
                        "data-cy": _vm.config.flyOutOptions,
                        textOrBorderColor: "white",
                        disabled: !_vm.hasSelected,
                        bg: _vm.hasSelected ? "val-button-blue" : "gray-400",
                      },
                      on: { click: _vm.openFlyout },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "transform rotate-45 h-3/4",
                        style: {
                          color: _vm.iconColors.WHITE,
                        },
                        attrs: { icon: ["fas", "arrow-up"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }