var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-flyout-component", [
    _c("div", { staticClass: "flex flex-col h-full" }, [
      _c("div", { staticClass: "flex flex-shrink-0 p-4 border-b" }, [
        _vm.title
          ? _c("div", { staticClass: "pl-2 text-2xl font-semibold" }, [
              _vm._v(_vm._s(_vm.title)),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex w-full h-full overflow-hidden" }, [
        _vm.students.length > 1
          ? _c(
              "div",
              {
                staticClass: "flex-grow flex-shrink-0 overflow-y-auto border-r",
              },
              _vm._l(_vm.students, function (student, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "block w-full p-4 pr-16 text-sm text-left border-l-4 cursor-pointer whitespace-nowrap focus:outline-none",
                    class: {
                      active: _vm.activeStudentIndex === index,
                      inActive: _vm.activeStudentIndex !== index,
                    },
                    attrs: { "data-cy": "studentButton" },
                    on: {
                      click: function ($event) {
                        return _vm.handleSelectStudent(index)
                      },
                    },
                  },
                  [
                    student.fullName
                      ? _c("span", [_vm._v(_vm._s(student.fullName))])
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "w-full h-full pr-4 overflow-x-auto" },
          [
            _vm._t("default", function () {
              return [_vm._v("default slot content for student flyout")]
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-shrink-0 p-4 border-t" },
        [
          _c(
            "button-component",
            {
              staticClass: "ml-auto mr-4",
              attrs: { "data-cy": "cancelButton" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "button-component",
            {
              staticClass: "w-32 mr-4",
              attrs: {
                textOrBorderColor: "white",
                bg: "val-button-blue",
                "data-cy": "doneButton",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("save")
                },
              },
            },
            [_vm._v("Save ")]
          ),
          _vm.students.length > 1
            ? _c(
                "button-component",
                {
                  staticClass: "mr-4",
                  attrs: { "data-cy": "nextStudentButton" },
                  on: { click: _vm.handleNextStudent },
                },
                [
                  _vm._v(" Next Student "),
                  _c("span", { staticClass: "pl-2" }, [_vm._v(">")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }