import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { GainsTableData } from './GainsTableData';

export class GainsDataProvider extends TableDataProvider<
  GainsTableData,
  Gains,
  SearchGainsDto
> {
  protected transformer = (t: Gains): GainsTableData => new GainsTableData(t);

  protected queryHandler = async (
    searchDto: SearchGainsDto
  ): Promise<SearchResponseDto<Gains>> => {
    const results: SearchResponseDto<Gains> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'gains', query: searchDto, model: Gains }
    );
    return results;
  };
}
