import { Census } from '@/models/Entities/MaterializedViews/Census';
import { PhaseType } from '@/models/Entities/Student/Constants';

export class CensusTableData {
  phase!: PhaseType;
  total!: number;

  constructor(census: Census) {
    this.phase = census.phase;
    this.total = census.total;
  }
}
