import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchStartPayDto } from '../../../../models/Dtos/searchStartPayDto';
import { StartPay } from '../../../../models/Entities/MaterializedViews/StartPay';
import { StartPayTableData } from './StartPayTableData';

export class StartPayDataProvider extends TableDataProvider<
  StartPayTableData,
  StartPay,
  SearchStartPayDto
> {
  protected transformer = (t: StartPay): StartPayTableData =>
    new StartPayTableData(t);

  protected queryHandler = async (
    searchDto: SearchStartPayDto
  ): Promise<SearchResponseDto<StartPay>> => {
    const results: SearchResponseDto<StartPay> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'startpay', query: searchDto, model: StartPay }
    );
    return results;
  };
}
