import { EMilitaryRank } from '@/enums/ECurrentGrade';
import { InPro } from '@/models/Entities/MaterializedViews/InPro';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { DisplayNameUtil } from '@/util/displayName';
export class InProTableData {
  candidateId!: number;
  candidate!: BaseCandidate;
  name!: string;
  ranking!: EMilitaryRank | null;
  gained!: string;
  lead!: string;
  welcome!: Date | null;
  gtc!: Date | null;
  inProStartDate!: Date | null;

  constructor(inpro: InPro) {
    this.candidateId = inpro.candidateId;
    this.candidate = inpro.candidate;
    if (inpro.firstName && inpro.lastName) {
      this.name = DisplayNameUtil.flattenName(
        inpro.firstName.toUpperCase(),
        inpro.lastName.toUpperCase()
      );
    }
    this.ranking = inpro.ranking;
    this.gained = inpro.gained ? 'Yes' : 'No';
    this.lead = inpro.lead ? 'Yes' : 'No';
    this.welcome = inpro.welcome;
    this.gtc = inpro.gtc;
    this.inProStartDate = inpro.inProStartDate;
  }
}
