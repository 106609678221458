import { Vue, Component } from 'vue-property-decorator';
import { Student } from '../models/Entities/Student/Student';

@Component
export default class UpdateStudentMixin extends Vue {
  async updateActiveStudent(student: Student): Promise<void> {
    try {
      // TODO: confirm error handling
      await this.$store.dispatch('studentModule/update', {
        id: student.id,
        payload: student
      });
      this.$store.dispatch('snackBarModule/enqueue', {
        message: 'Student has updated successfully',
        icon: 'checkGreen'
      });
    } catch (err: any) {
      this.$store.dispatch('snackBarModule/enqueue', {
        message:
          err.message ??
          'There was an error updating this student please try again.',
        icon: 'x'
      });
    }
  }
}
