import { render, staticRenderFns } from "./StudentGroupInfoComponent.vue?vue&type=template&id=3747c96c&"
import script from "./StudentGroupInfoComponent.vue?vue&type=script&lang=ts&"
export * from "./StudentGroupInfoComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3747c96c')) {
      api.createRecord('3747c96c', component.options)
    } else {
      api.reload('3747c96c', component.options)
    }
    module.hot.accept("./StudentGroupInfoComponent.vue?vue&type=template&id=3747c96c&", function () {
      api.rerender('3747c96c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StudentDashboard/Gains/StudentGroupInfoComponent.vue"
export default component.exports