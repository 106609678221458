import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { HoldsTableData } from './HoldsTableData';
import { Hold } from '@/models/Entities/MaterializedViews/Hold';
import { SearchHoldDto } from '@/models/Dtos/searchHoldDto';

export const holdsTableConfigBuilder: TableConfigBuilder<
  HoldsTableData,
  Hold,
  never,
  SearchHoldDto
> = (): ITableConfiguration<HoldsTableData, Hold, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: HoldsTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.candidateId }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'Hold Type',
        render: 'holdType',
        sortKey: 'flightPayHoldtype'
      },
      {
        columnTitle: 'Days',
        render: 'days',
        sortKey: 'days'
      },
      {
        columnTitle: 'Order End',
        render: 'orderEnd',
        sortKey: 'orderEndDate'
      }
    ]
  };
};
