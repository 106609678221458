import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchCensusDto } from '@/models/Dtos/searchCensusDto';
import { Census } from '@/models/Entities/MaterializedViews/Census';
import { CensusTableData } from './CensusTableData';

export class CensusDataProvider extends TableDataProvider<
  CensusTableData,
  Census,
  SearchCensusDto
> {
  protected transformer = (t: Census): CensusTableData =>
    new CensusTableData(t);

  protected queryHandler = async (
    searchDto: SearchCensusDto
  ): Promise<SearchResponseDto<Census>> => {
    const results: SearchResponseDto<Census> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'census', query: searchDto, model: Census }
    );
    return results;
  };
}
