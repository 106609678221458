var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex flex-col max-h-screen" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "p-4 overflow-y-auto shadow-inner" },
        [
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "inProccessingStartDate",
              label: "Inpro Start",
              alignment: "top",
            },
            model: {
              value: _vm.student.inProcessingInproccessingstartdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingInproccessingstartdate", $$v)
              },
              expression: "student.inProcessingInproccessingstartdate",
            },
          }),
          _c("dropdown-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "isPriorAircrew",
              label: "Prior Service",
              values: _vm.dropdownItems,
            },
            model: {
              value: _vm.student.priorAirCrewIsprioraircrew,
              callback: function ($$v) {
                _vm.$set(_vm.student, "priorAirCrewIsprioraircrew", $$v)
              },
              expression: "student.priorAirCrewIsprioraircrew",
            },
          }),
          _c("dropdown-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "hasPrivateLicense",
              label: "PPL",
              values: _vm.dropdownItems,
            },
            model: {
              value: _vm.student.priorAirCrewHasprivatelicense,
              callback: function ($$v) {
                _vm.$set(_vm.student, "priorAirCrewHasprivatelicense", $$v)
              },
              expression: "student.priorAirCrewHasprivatelicense",
            },
          }),
          _c("text-input", {
            staticClass: "mb-6",
            attrs: { "data-cy": "DoDId", type: "number", label: "DoD ID" },
            model: {
              value: _vm.student.identificationInformationEdipi,
              callback: function ($$v) {
                _vm.$set(_vm.student, "identificationInformationEdipi", $$v)
              },
              expression: "student.identificationInformationEdipi",
            },
          }),
          _c(
            "div",
            { staticClass: "flex items-center mb-6" },
            [
              _c("checkbox", {
                staticClass: "mr-4",
                attrs: {
                  "data-cy": "Lead",
                  label: "Peer Lead",
                  checkboxItems: [{ label: "", value: true }],
                },
                model: {
                  value: _vm.student.inProcessingPeerleader,
                  callback: function ($$v) {
                    _vm.$set(_vm.student, "inProcessingPeerleader", $$v)
                  },
                  expression: "student.inProcessingPeerleader",
                },
              }),
              _vm.student.inProcessingPeerleader
                ? _c(
                    "button-component",
                    {
                      attrs: {
                        "data-cy": "emailLead",
                        textOrBorderColor: "white",
                        bg: "val-button-blue",
                      },
                      on: { click: _vm.handleEmailLeadClick },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("icon-component", {
                            staticClass: "flex-grow-0 flex-shrink-0 mr-2",
                            attrs: { width: 16, height: 10, name: "email" },
                          }),
                          _c("span", [_vm._v("E-mail Lead")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "officerTrainingSchoolStartDate",
              label: "OTS Start",
              alignment: "bottom",
            },
            model: {
              value: _vm.student.inProcessingOfficertrainingschoolstartdate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.student,
                  "inProcessingOfficertrainingschoolstartdate",
                  $$v
                )
              },
              expression: "student.inProcessingOfficertrainingschoolstartdate",
            },
          }),
          _c("todays-date-button-component", {
            staticClass: "mb-6",
            attrs: {
              "data-cy": "welcomeEmailSentDate",
              label: "Welcome e-mail",
              alignment: "bottom",
            },
            model: {
              value: _vm.student.inProcessingWelcomeemailsentdate,
              callback: function ($$v) {
                _vm.$set(_vm.student, "inProcessingWelcomeemailsentdate", $$v)
              },
              expression: "student.inProcessingWelcomeemailsentdate",
            },
          }),
        ],
        1
      ),
      _c(
        "email-modal-component",
        {
          ref: "emailModal",
          attrs: {
            to: _vm.student.contactInformationEmail,
            subject: "340th UFT Inprocessing Team Lead Responsibilities",
          },
        },
        [_c("team-lead-email-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-shrink-0 p-4 border-b" }, [
      _c("div", { staticClass: "font-semibold" }, [
        _vm._v("Student Information"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }