var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("gains-flyout-component", { ref: "gainsFlyout" }),
      _c("in-processing-flyout-component", { ref: "inprocessingFlyout" }),
      _c("ots-flyout-component", { ref: "otsFlyout" }),
      _c("orders-flyout-component", { ref: "ordersFlyout" }),
      _c("start-pay-flyout-component", { ref: "startpayFlyout" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }