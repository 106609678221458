import { Type, Transform, plainToClass } from 'class-transformer';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { EMilitaryRank } from '@/enums/ECurrentGrade';
import { BaseCandidate } from '../BaseCandidate';

export class InPro {
  @Type(
    /* istanbul ignore next */
    () => Number
  )
  candidateId!: number;
  @Type(
    /* istanbul ignore next */
    () => BaseCandidate
  )
  candidate!: BaseCandidate;
  firstName!: string | null;
  lastName!: string | null;
  ranking!: EMilitaryRank | null;
  @Type(
    /* istanbul ignore next */
    () => Boolean
  )
  gained!: boolean;
  @Type(
    /* istanbul ignore next */
    () => Boolean
  )
  lead!: boolean;
  @Type(
    /* istanbul ignore next */
    () => Date
  )
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  welcome!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  gtc!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  inProStartDate!: Date | null;

  constructor(partial?: Partial<InPro>) {
    if (partial) {
      Object.assign(this, plainToClass(InPro, partial));
    }
  }
}
