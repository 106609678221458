
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';

@Component<TodaysDateButtonComponent>({
  components: { DateSelector },
  inheritAttrs: false
})
export default class TodaysDateButtonComponent extends Vue {
  @Prop()
  readonly readonly!: boolean;
}
