import DateTableElementComponent from '@/components/Table/Elements/DateTableElementComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { SearchOrdersEndDto } from '@/models/Dtos/searchOrdersEndDto';
import { OrdersEnd } from '@/models/Entities/MaterializedViews/OrdersEnd';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TableConfigBuilder } from '../../../Table/models/TableConfigBuilder';
import { OrdersEndTableData } from './OrdersEndTableData';

export const ordersEndTableConfigBuilder: TableConfigBuilder<
  OrdersEndTableData,
  OrdersEnd,
  never,
  SearchOrdersEndDto
> = (): ITableConfiguration<OrdersEndTableData, OrdersEnd, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: OrdersEndTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.id }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'End',
        render: {
          componentRef: DateTableElementComponent,
          componentProps: (rowData: OrdersEndTableData) => {
            return {
              date: rowData.end
            };
          }
        },
        sortKey: 'orderEndDate'
      },
      {
        columnTitle: 'Days Left',
        render: 'daysLeft',
        sortKey: 'orderEndDate'
      }
    ]
  };
};
