
import ButtonComponent from '@/components/ButtonComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { EIconColors } from '@/enums';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import { Component, Emit, InjectReactive, Prop } from 'vue-property-decorator';
import { IMiniTableConfiguration } from './MiniTableConfiguration';

@Component<MiniTable>({
  components: {
    AsyncTableComponent,
    ButtonComponent,
    FontAwesomeIcon
  },
  inheritAttrs: false
})
export default class MiniTable extends Vue {
  @InjectReactive({ from: 'expanded', default: false })
  expanded!: boolean;

  @Prop({ default: '' })
  title!: string;

  @Prop()
  config!: IMiniTableConfiguration<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >;

  iconColors = EIconColors;

  @Prop()
  tableSearchService!: TableSearchService<
    unknown,
    unknown,
    never,
    SearchDto<unknown, never>
  >;

  async created(): Promise<void> {
    await this.tableSearchService.queryData();
  }

  get hasSelected(): boolean {
    return this.tableSearchService.selectedRowIndexes.length > 0;
  }

  async openFlyout(): Promise<void> {
    if (!this.config.flyOutOptions) {
      return;
    }
    const tableData = this.tableSearchService.selectedData;
    if (tableData.length <= 0) {
      return;
    }
    await this.config.flyOutOptions.openFlyoutHandler(tableData);
  }

  get hasFlyout(): boolean {
    return this.config.flyOutOptions !== undefined;
  }

  get storageKey(): string {
    return this.expanded
      ? `340th-table-${this.title}-expanded`
      : `340th-table-${this.title}`;
  }

  @Emit('titleClick')
  emitTitleClick(): string {
    this.tableSearchService.paginationItemsPerPage = 10;
    this.tableSearchService.reset();
    return this.title;
  }
}
